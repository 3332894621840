<template>
  <b-modal
    lazy
    centered
    id="user-details"
    :title="
      $t('user.user_details', {
        id: detailTarget ? ' #' + detailTarget.user_id : '',
      })
    "
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    footer-class="py-2"
    body-class="py-0"
    @hidden="hideModal"
  >
    <form id="user-details-form">
      <UserDetails v-if="detailTarget" :user="detailTarget" :isAffiliate="true">
        <template #phone="{ val }">
          <div :class="`mt-3`">
            <span class="font-weight-normal text-sm">{{ $t('user.phone') }}</span>
            <span class="ml-1 text-danger">*</span>
            <span class="ml-3 font-weight-light">{{ val ? val : $t('no_data') }}</span>
          </div>
        </template>
        <template #address="{ address }">
          <br />
          <AddressDetails :key="`address-details-${address ? address.id : 'no data'}`" :address="address" :userStore="detailTarget.store_id" />
        </template>
      </UserDetails>
      <br />
    </form>
    <template #modal-footer="{ cancel }">
      <b-button variant="outline-secondary" @click="cancel">{{ $t('action.close') }}</b-button>
    </template>
  </b-modal>
</template>
<script>
import AddressDetails from '@/components/Users/AddressDetails.vue'
import UserDetails from '@/components/Users/UserDetails.vue'
export default {
  components: {
    UserDetails,
    AddressDetails,
  },
  name: 'UserDetailModal',
  props: {
    detailTarget: Object,
    hideModal: Function,
    user: Object,
    isAffiliate: Boolean,
  },
}
</script>
