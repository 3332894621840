import { render, staticRenderFns } from "./CompetitionDetails.vue?vue&type=template&id=15b6d9fc&scoped=true&"
import script from "./CompetitionDetails.vue?vue&type=script&lang=js&"
export * from "./CompetitionDetails.vue?vue&type=script&lang=js&"
import style0 from "./CompetitionDetails.vue?vue&type=style&index=0&id=15b6d9fc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15b6d9fc",
  null
  
)

export default component.exports