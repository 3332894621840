<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <CompetitionList :fields="fields" :queries="queries" :getter="getList" :isLoading.sync="isLoading" :rowClicked="rowClicked">
      <template #tag="{ comp }">
        <b-form-select
          :ref="`tag${comp.id}`"
          :disabled="comp.status !== 0 && comp.status !== 1"
          :value="comp.tag"
          style="min-width: 5rem; width: fit-content"
          class="text-uppercase"
          @change="changeTag(comp, $event)"
          ><b-form-select-option :selected="!comp.tag" :value="''">{{ $t('input.empty') }}</b-form-select-option>
          <b-form-select-option v-for="(option, index) in tags" :key="'comp-tag-' + index" :value="option" :selected="comp.tag === option" class="text-uppercase">
            {{ $t(`competition.tags[${index}]`) }}
          </b-form-select-option>
        </b-form-select>
      </template>
      <template #is_approval="{ comp }">
        <CompetitionApprovalStatus
          v-if="String(comp['is_approval'])"
          :value="comp.is_approval"
          :onClick="() => viewApprovalLogs(comp.id)"
          icon
          abbrev
          class="d-block mt-1"
          style="min-width: fit-content; width: fit-content; cursor: pointer"
          v-b-tooltip.hover.left="`View all approval info`" />
      </template>
      <template #tickets="{ comp }">
        <div v-b-tooltip.hover.top="`Sold / Expected / Total`" @click="viewTickets(comp)" class="w-100 d-flex flex-row flex-wrap justify-content-center align-items-center action">
          {{ comp.sold_ticket_amount }} / {{ comp.target_sales }} / {{ comp.stock_amount }}
        </div>
        <div class="position-relative w-100 rounded text-white text-xs mt-2" style="height: 14px; background: silver">
          <div
            v-b-tooltip.hover.right="'Expected Tickets: ' + comp.target_sales_pct + '%'"
            class="position-absolute h-100 bg-yellow rounded"
            :style="`width: ${comp.target_sales_pct}%; max-width: 100%;`"></div>
          <div
            v-b-tooltip.hover.left="'Sold Tickets: ' + comp.current_sales_pct + '%'"
            class="position-absolute h-100 bg-green rounded"
            :style="`width: ${comp.current_sales_pct}%; max-width: 100%; opacity: 0.5;`"></div>
        </div>
        <div v-b-tooltip.hover.bottom="`Available Ticket Amount`" class="w-100 text-center mt-2">
          {{ comp.available_ticket_amount }}
        </div>
      </template>
      <template #reward_amount="{ comp }">
        <div v-b-tooltip.hover.top="`Prizes`" @click="viewRewards(comp)" class="w-100 d-flex flex-row flex-wrap justify-content-center align-items-center action">
          {{ comp.reward_amount }}
        </div>
      </template>
      <template #winner_amt="{ comp }">
        <span @click="viewWinners(comp)" class="action"> {{ comp.live_winner_amt || '-' }} / {{ comp.instant_winner_amt || '-' }} / {{ comp.live_winner_amt + comp.instant_winner_amt || '-' }} </span>
      </template>
      <template #publication_items="{ comp }">
        <WinnerStatus :value="comp.winner_status" :onClick="() => viewWinners(comp)" />
        <b-badge @click="viewEntryList(comp)" pill class="d-block mt-1" :variant="comp.entry_status == 1 ? 'success' : 'light'" style="cursor: pointer">
          entry{{ comp.entry_status == 1 ? '' : ' not' }} published
        </b-badge>
        <b-badge @click="viewDraws(comp)" pill class="d-block mt-1" :variant="comp.video_status == 1 ? 'success' : 'light'" style="cursor: pointer">
          video{{ comp.video_status == 1 ? '' : ' not' }} published
        </b-badge>
      </template>
      <template #actions="{ comp }">
        <b-button @click="pushCompEdit(comp)" class="d-block w-100"><b-icon-pencil class="mr-1" />{{ $t('action.edit') }}</b-button>
        <b-button v-if="comp.status === -1 || (comp.status == 4 && comp.rewards_settled == 1)" @click="onDuplicate(comp, queries.page)" class="d-block mt-1">
          <b-icon-plus class="mr-1" />{{ $t('action.duplicate') }}
        </b-button>
        <b-dropdown
          v-else
          :text="$t('action.more')"
          lazy
          dropright
          no-caret
          :ref="'ddMore' + comp.id"
          :disabled="comp.status === -1 || (comp.status == 4 && comp.rewards_settled == 1)"
          @shown="comp['ddShow'] = true"
          @hidden="comp['ddShow'] = false"
          class="d-block mt-1"
          toggle-class="w-100">
          <template #button-content><b-icon-caret-down-fill class="mr-1" />{{ $t('action.more') }}</template>
          <!-- all status -->
          <b-dropdown-item-button @click="onDuplicate(comp)">{{ $t('action.duplicate') }}</b-dropdown-item-button>
          <!-- 0: Draft -->
          <b-dropdown-item-button v-if="showBtn(comp, 0, true)" @click="onPublish(comp)">{{ $t('action.publish') }}</b-dropdown-item-button>
          <template v-if="showBtn(comp, 0)">
            <b-dropdown-item-button button-class="text-danger" @click="onRemove(comp)">{{ $t('action.remove') }}</b-dropdown-item-button>
            <b-dropdown-item-button @click="pushCompEdit(comp)">Set Instant Win</b-dropdown-item-button>
          </template>
          <!-- 1: Published -->
          <template v-if="comp.status === 1">
            <b-dropdown-item-button @click="onPopup(comp)">{{ $t('competition.set_as_popup') }}</b-dropdown-item-button>
            <b-dropdown-item-button button-class="text-danger" @click="onClose(comp)">{{ $t('action.close') }}</b-dropdown-item-button>
          </template>
          <!-- 2: Drawing -->
          <template v-if="comp.status == 2">
            <b-dropdown-item-button @click="viewTickets(comp)">{{ $t('competition.select_winner') }}</b-dropdown-item-button>
          </template>
          <b-dropdown-item-button v-if="comp.status == 2 || comp.status == 3" @click="assignPrize(comp)">{{ $t('competition.assign_prize') }}</b-dropdown-item-button>
          <b-dropdown-item-button v-if="comp.status >= 2" @click="viewEntryList(comp)">{{ $t('competition.publish_entry') }}</b-dropdown-item-button>
          <!-- 3: Rewarding -->
          <template v-if="showBtn(comp, 3)">
            <!-- <b-dropdown-item-button @click="publishVideoDraw(competition)">{{ $t('competition.publish_video') }}</b-dropdown-item-button> -->
            <b-dropdown-item-button @click="viewDraws(comp)">{{ $t('competition.publish_video') }}</b-dropdown-item-button>
            <b-dropdown-item-button @click="viewWinners(comp)">{{ $t('competition.publish_winner') }}</b-dropdown-item-button>
          </template>
        </b-dropdown>
      </template>
    </CompetitionList>

    <b-modal
      lazy
      centered
      id="confirm"
      v-model="showConfirm"
      :title="$t('action.confirm_action')"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="py-2"
      footer-class="py-2"
      body-class="py-0"
      cancel-variant="outline-secondary"
      ok-variant="outline-primary"
      :ok-title="$t('action.confirm')"
      @ok="generalMutator"
      @cancel="hideModal"
      @hide="hideModal">
      <p v-if="confirmMsg">{{ confirmMsg }}</p>
      <b-form-group v-if="showClose" class="mt-2 mb-0">
        <b-form-checkbox id="force-close" name="force-close" v-model="confirmParams['force']">{{ $t('competition.force_close') }}</b-form-checkbox>
        <p>{{ $t('competition.force_close_notice') }}</p>
      </b-form-group>
    </b-modal>

    <CompSetPopup :comp.sync="popTarget" />

    <AssignPrize :comp.sync="assignTarget" />

    <CompetitionApprovalLogs :competitionId="approvalLogsId" />
  </card>
</template>
<script>
import CompetitionApprovalLogs from '@/components/Competitions/CompetitionApprovalLogs.vue'
import CompetitionApprovalStatus from '@/components/Competitions/CompetitionApprovalStatus.vue'
import CompetitionList from '@/components/Competitions/CompetitionList.vue'
import CompSetPopup from '@/components/Competitions/CompSetPopup.vue'
import WinnerStatus from '@/components/Competitions/WinnerStatus.vue'
import AssignPrize from '@/components/Winners/AssignPrize.vue'
import routerMixin from '@/mixins/router-mixin'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { floatDivision, floatMultiplication, formatWithCurrency } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'

export default {
  mixins: [routerMixin],
  components: {
    CompetitionApprovalLogs,
    CompetitionApprovalStatus,
    CompetitionList,
    CompSetPopup,
    WinnerStatus,
    AssignPrize,
  },
  data() {
    return {
      isLoading: false,
      confirmMsg: null,
      confirmParams: {},
      showPublish: false,
      showRemove: false,
      showClose: false,
      showSetTags: false,
      tagTarget: null,
      popTarget: null,
      assignTarget: null,
      queries: {
        id: null,
        status: null,
        is_approval: null,
        store_ids: null,
        catalog_ids: null,
        title: null,
        orderBy: '',
        page: 1,
        perPage: 10,
        ...this.$route.params?.prev,
      },
      afterSearch: null,
      tags: ['on_sale', 'best_odds', 'guaranteed_draw', 'selling_fast', 'draw_today', 'draw_tomorrow', 'tomorrow', 'instant_win'],
      approvalLogsId: null,
    }
  },
  computed: {
    ...mapGetters({
      competitionList: 'request/competitionList',
      competitionDetails: 'request/competitionDetails',
      videoDrawList: 'request/videoDrawList',
      selfStore: 'store',
      allowMultiStores: 'allowMultiStores',
    }),
    fields() {
      return [
        { key: 'id', label: this.$t('general.id'), sortable: true, stickyColumn: true },
        { key: 'title', label: this.$t('general.title'), sortable: true, stickyColumn: true },
        { key: 'image', label: this.$t('general.top_image') },
        { key: 'store_id', label: this.$tc('general.store', 1), class: 'text-center' },
        { key: 'status', label: this.$t('general.status'), sortable: true, sortKey: 'status', class: 'text-center' },
        { key: 'tag', label: this.$tc('competition.tag', 1), class: 'px-2', thClass: 'text-center' },
        { key: 'action', label: this.$tc('general.action', 1), class: 'px-2', thClass: 'text-center' },
        { key: 'tickets', label: this.$tc('general.ticket', 2), sortable: true, sortKey: 'stock_amount', formatter: (v, k, i) => this.salesFormula(i), thClass: 'text-center' },
        { key: 'reward_amount', label: this.$t('competition.reward_amount'), class: 'text-center' },
        { key: 'winner_amt', label: this.$t('competition.winner_amt'), class: 'text-center', tooltip: this.$t('competition.winner_amt_tip') },
        { key: 'prices', label: this.$tc('competition.price', 1), sortable: true, sortKey: 'prices', class: 'text-right' },
        { key: 'cost', label: this.$t('competition.prize_cost'), sortable: true, sortKey: 'cost', class: 'text-right' },
        { key: 'cash_alt', label: this.$t('competition.cash_alt'), sortable: true, sortKey: 'cash_alt', class: 'text-right' },
        { key: 'start_time', label: this.$t('competition.opening_date'), sortable: true, sortKey: 'start_time' },
        { key: 'end_time', label: this.$t('competition.closing_date'), sortable: true, sortKey: 'end_time' },
        { key: 'draw_date', label: this.$t('competition.draw_date'), sortable: true, sortKey: 'draw_date', formatter: (v) => formatLocalDateTime(v) },
        { key: 'publication_items' },
        { key: 'created_at', label: this.$t('general.created_at'), sortable: true, sortKey: 'created_at' },
        { key: 'admin.name', label: this.$t('general.updated_by') },
      ]
    },
    showConfirm: {
      get() {
        return this.showPublish || this.showRemove || this.showClose || this.showSetTags
      },
      set(val) {
        return val
      },
    },
  },
  methods: {
    formatWithCurrency,
    getList(callback) {
      this.isLoading = true
      this.$store
        .dispatch('request/getCompetitionList', this.queries)
        .then(() => {
          this.isLoading = false
          this.afterSearch = this.allowMultiStores && this.queries.store_ids && this.queries.store_ids.length == 3 ? parseInt(this.queries.store_ids[1]) : null
          if (callback) {
            callback()
          }
        })
        .catch(() => (this.isLoading = false))
    },
    viewTickets(comp) {
      this.$router.push({
        name: 'Competition Tickets',
        // path: '/competitions/competition-list/tickets', // `params` cannot be used alongside `path`
        query: { id: comp.id, status: comp.status, left: comp.available_ticket_amount, limit: comp.user_limit_amount, title: encodeURIComponent(comp.title) },
        params: comp,
      })
    },
    viewRewards(comp) {
      this.$router.push({ name: 'Prize List', query: { id: comp.id, status: comp.status, title: encodeURIComponent(comp.title), live_winner_amt: comp.live_winner_amt }, params: comp })
    },
    viewEntryList(comp) {
      this.$router.push({ name: 'Entry Lists', params: { comp_id: comp.id } })
    },
    viewDraws(comp) {
      this.$router.push({ name: 'Video Draws', params: { comp_id: comp.id } })
    },
    viewWinners(comp) {
      this.$router.push({ name: 'Winner List', params: { comp_id: comp.id } })
    },
    onPopup(comp) {
      this.popTarget = comp
      this.$bvModal.show('set-popup')
    },
    assignPrize(comp) {
      this.assignTarget = comp
      this.$bvModal.show('assign-prize')
    },
    changeTag(obj, val) {
      if (val !== obj.tag) {
        this.tagTarget = obj
        this.confirmMsg = this.$t('competition.change_tag_notice[0]', [obj.title, obj.id, this.$t('competition.tags')[this.tags.indexOf(val)] ?? this.$t('input.empty')])
        this.confirmParams = {
          url: 'request/setTags',
          successMsg: this.$t('competition.change_tag_notice[1]'),
          errorMsg: this.$t('competition.change_tag_notice[2]'),
          competition_id: obj.id,
          code: 'tag',
          position: 1,
          status: 1,
          value: val ?? null,
        }
        this.showSetTags = true
      }
    },
    onDuplicate(comp) {
      this.$router.push({
        name: 'Competition Create',
        // path: '/competitions/competition-list/create', // `params` cannot be used alongside `path`
        params: comp,
      })
    },
    getTargetStore(store_id) {
      return this.allowMultiStores ? this.afterSearch ?? store_id : this.selfStore
    },
    showBtn(comp, status, restricted) {
      const targetStore = this.getTargetStore(comp.store_id)
      if (targetStore == comp.store_id) {
        return comp.status == status
      } else if (restricted && comp.status < 1) {
        return false
      } else {
        return comp.stores[0] ? comp.stores[0]?.status == status : false
      }
    },
    onPublish(comp) {
      const targetStore = this.getTargetStore(comp.store_id)
      const targetStoreName = [this.$t('uk'), this.$t('address.mexico')][targetStore - 1]
      this.confirmMsg = this.$t(`competition.publish_notice[${targetStore == comp.store_id ? 0 : 3}]`, [comp.title, comp.id, targetStoreName])
      this.confirmParams = {
        url: 'request/publishCompetition',
        id: comp.id,
        store_id: targetStore,
        successMsg: this.$t('competition.publish_notice[1]'),
        errorMsg: this.$t('competition.publish_notice[2]'),
      }
      this.showPublish = true
    },
    onRemove(comp) {
      const targetStore = this.getTargetStore(comp.store_id)
      const targetStoreName = [this.$t('uk'), this.$t('address.mexico')][targetStore - 1]
      this.confirmMsg = this.$t('competition.remove_notice[0]', [comp.title, comp.id, targetStoreName])
      this.confirmParams = {
        url: 'request/updateCompetition',
        id: comp.id,
        successMsg: this.$t('competition.remove_notice[1]'),
        errorMsg: this.$t('competition.remove_notice[2]'),
      }
      if (targetStore == comp.store_id) {
        this.confirmParams['status'] = -1
      } else {
        this.confirmParams['stores'] = [{ id: comp.stores[0].id, status: -1, store_id: comp.stores[0].store_id }]
      }
      this.showRemove = true
    },
    onClose(comp) {
      this.confirmMsg = this.$t('competition.close_notice[0]', [comp.title, comp.id])
      this.confirmParams = {
        url: 'request/closeCompetition',
        id: comp.id,
        successMsg: this.$t('competition.close_notice[1]'),
        errorMsg: this.$t('competition.close_notice[2]'),
      }
      this.showClose = true
    },
    generalMutator() {
      const { url, ...rest } = this.confirmParams
      this.isLoading = true
      this.$store
        .dispatch(url, rest)
        .then((res) => {
          this.isLoading = false
          if (res.status === 200) {
            this.getList()
            this.hideModal()
          }
        })
        .catch(() => (this.isLoading = false))
    },
    hideModal() {
      this.showPublish = false
      this.showRemove = false
      this.showClose = false
      this.showSetTags = false
      this.showConfirm = false
    },
    rowClicked(item, index, evt) {
      if (evt?.target?.cellIndex >= 0 && !['1', '2', '4', '5', '6', '7'].includes(String(evt.target.cellIndex)) && item.status !== -1 && !(item.status == 4 && item.rewards_settled == 1)) {
        const dd = this.$refs[`ddMore${item.id}`]
        if (item.ddShow) {
          dd.hide()
        } else {
          dd.show()
        }
      }
    },
    salesFormula(item) {
      const percentage = floatMultiplication(floatDivision(item.sold_ticket_amount, item.stock_amount), 100).toFixed(2)
      const targetSales = Math.ceil(
        floatDivision(String(item.cost).replaceAll(/[Mex$£]/gi, ''), item.prices.find((x) => x.store_id == this.$cookies.get('Store'))?.amount || item.prices[0]?.amount || 1)
      )
      const targetPercentage = floatMultiplication(floatDivision(targetSales, item.stock_amount), 100).toFixed(2)
      this.$set(item, 'current_sales_pct', percentage)
      this.$set(item, 'target_sales', targetSales)
      this.$set(item, 'target_sales_pct', targetPercentage)
    },
    viewApprovalLogs(id) {
      if (id) {
        this.approvalLogsId = String(id)
        this.$bvModal.show('comp-approval-logs')
      }
    },
  },
  watch: {
    showSetTags(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.$refs[`tag${this.tagTarget.id}`].value = this.tagTarget.tag
        this.$refs[`tag${this.tagTarget.id}`].localValue = this.tagTarget.tag
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$set(from.params, 'preservedParams', this.queries)
    return next()
  },
}
</script>
<style scoped>
::v-deep .modal-dialog {
  width: fit-content;
  min-width: 35%;
  max-width: 50%;
}
@media screen and (max-width: 1024px) {
  ::v-deep .modal-dialog {
    min-width: 50%;
    max-width: 80%;
  }
}
</style>
