<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <AffiliateList
      :fields="[
        { key: 'id', label: $tc('affiliate.referrer_id'), sortable: true },
        { key: 'user_name', label: $t('affiliate.referrer_username'), stickyColumn: true },
        {
          key: 'total_referrals',
          label: $t('affiliate.total_referrals'),
          sortable: true,
        },
        {
          key: 'pending_commission_amount',
          label: $t('affiliate.pending_commission_amount'),
          sortable: true,
          formatter: v => formatWithGBP(v),
        },
        {
          key: 'available_commission_amount',
          tooltip: $t('affiliate.available_commission_tooltip'),
          label: $t('affiliate.available_commission_amount'),
          sortable: true,
          formatter: v => formatWithGBP(v),
        },
        {
          key: 'commission_balance',
          label: $t('affiliate.commission_balance'),
          sortable: true,
        },
        {
          key: 'total_commission_amount',
          label: $t('affiliate.total_earned'),
          sortable: true,
          formatter: v => formatWithGBP(v),
        },
        {
          key: 'total_revenue',
          label: $t('affiliate.total_purchased'),
          sortable: true,
          formatter: v => formatWithGBP(v),
        },
        {
          key: 'actions',
          label: $tc('general.action', 1),
        },
        {
          key: 'campaign_amount',
          label: $t('affiliate.campaign_amount'),
          sortable: true,
        },
        {
          key: 'status',
          label: $t('general.status'),
          tdClass: 'text-uppercase',
        },
        {
          key: 'created_at',
          label: $t('general.created_at'),
          sortable: true,
          formatter: v => formatLocalDateTime(v),
        },
      ]"
    >
      <template #user_name="{ user }">
        <span class="action" @click="viewDetails(user)">{{ user.user_name }}</span>
      </template>
      <template #total_referrals="{ user }">
        <span class="action" @click="viewReferrals(user)">{{ user.total_referrals }}</span>
      </template>
      <template #campaign_amount="{ user }">
        <span class="action" @click="viewCampaigns(user)">{{ user.campaign_amount }}</span>
      </template>
      <template #commission_balance="{ user }">
        <span>{{ formatWithGBP(parseFloat(user.pending_commission_amount) + parseFloat(user.available_commission_amount)) }}</span>
      </template>
      <template #status="{ user }">
        <b-badge
          v-if="user.store_id == 1"
          :variant="user.status ? 'success' : 'danger'"
          @click="viewActivation(user)"
          v-b-tooltip.hover.top="user.status ? $t('action.deactivate') : $t('action.activate')"
          class="font-weight-bold text-uppercase"
          style="cursor: pointer"
        >
          {{ user.status == 1 ? '✅ ' + $t('status.active') : '🚫 ' + $t('status.inactive') }}
        </b-badge>
        <bool-badge v-else :value="user.status" :text="user.status ? $t('status.active') : $t('status.inactive')" class="font-weight-bold text-uppercase" />
      </template>
      <template #actions="{ user }">
        <!--<b-button @click="viewReferrals(user)" v-b-tooltip.hover.topleft="$t('affiliate.referrals')"><b-icon-people /></b-button>-->
        <b-button @click="viewTransactions(user)" v-b-tooltip.hover.top="$t('affiliate.transactions')"><b-icon-credit-card /></b-button>
      </template>
    </AffiliateList>

    <b-modal
      lazy
      centered
      id="affiliate-referrals-modal"
      :title="`${$tc('general.affiliate', 1)} #${referralsTarget ? referralsTarget.id : ''} ${$t('affiliate.referred_users')}`"
      title-class="w-150 d-flex justify-content-center align-self-center"
      header-class="py-2"
      body-class="pt-0"
      hide-footer
      @hidden="hideModal"
    >
      <AffiliateReferralList
        id="affiliate-referrals-modal"
        :fields="[
          { key: 'id', label: $tc('affiliate.referral_id'), sortable: true },
          {
            key: 'affiliate_name',
            label: $t('affiliate.referrer_username'),
            stickyColumn: true,
          },
          { key: 'campaign_name', label: $tc('affiliate.campaign', 1) },
          { key: 'user_name', label: $t('affiliate.referred_username') },
          { key: 'refer_code', label: $t('affiliate.refer_code') },
          {
            key: 'total_orders',
            label: $t('affiliate.total_orders'),
            sortable: true,
          },
          {
            key: 'total_revenue',
            label: $t('affiliate.total_purchased'),
            sortable: true,
            formatter: v => formatWithGBP(v),
          },
          {
            key: 'total_commission_amount',
            tooltip: $t('affiliate.available_commission_tooltip'),
            label: $t('affiliate.total_earned'),
            sortable: true,
            formatter: v => formatWithGBP(v),
          },
          {
            key: 'created_at',
            label: $t('user.registered_at'),
            sortable: true,
            formatter: v => formatLocalDateTime(v),
          },
        ]"
        :affiliateDetails="referralsTarget"
      />
    </b-modal>

    <b-modal
      lazy
      centered
      id="affiliate-transactions-modal"
      :title="`${$tc('general.affiliate', 1)} #${transactionTarget ? transactionTarget.id : ''} ${$t('affiliate.transactions')}`"
      title-class="w-150 d-flex justify-content-center align-self-center"
      header-class="py-2"
      body-class="pt-0"
      hide-footer
      @hidden="hideModal"
    >
      <AffiliateTransactionList
        id="affiliate-transactions-modal"
        :fields="[
          {
            key: 'id',
            stickyColumn: true,
            label: $t('general.id'),
            formatter: val => '#' + val.toString(),
            sortable: true,
          },
          {
            key: 'affiliate_name',
            label: $tc('affiliate.referrer_username', 1),
          },
          {
            key: 'withdraw_amount',
            label: $t('affiliate.withdraw_amount'),
            sortable: true,
            formatter: v => formatWithGBP(v),
          },
          {
            key: 'commission_before',
            label: $t('affiliate.balance_before'),
            sortable: true,
            formatter: v => formatWithGBP(v),
          },
          {
            key: 'commission_after',
            label: $t('affiliate.balance_after'),
            sortable: true,
            formatter: v => formatWithGBP(v),
          },
          {
            key: 'comment',
            label: $t('affiliate.comment'),
          },
          {
            key: 'status',
            label: $t('general.status'),
            sortable: true,
          },
          {
            key: 'created_at',
            label: $t('general.created_at'),
            sortable: true,
            formatter: v => formatLocalDateTime(v),
          },
        ]"
        :affiliateDetails="transactionTarget"
      />
    </b-modal>

    <b-modal
      lazy
      centered
      id="affiliate-campaigns-modal"
      :title="`${$tc('general.affiliate', 1)} #${campaignTarget ? campaignTarget.id : ''} ${$tc('affiliate.campaign', 2)}`"
      title-class="w-150 d-flex justify-content-center align-self-center"
      header-class="py-2"
      body-class="pt-0"
      hide-footer
      @hidden="hideModal"
    >
      <AffiliateCampaignList
        id="affiliate-campaigns-modal"
        :fields="[
          {
            key: 'id',
            stickyColumn: true,
            label: $t('general.id'),
            sortable: true,
          },
          {
            key: 'affiliate_name',
            label: $t('affiliate.referrer_username'),
          },
          {
            key: 'title',
            label: $t('affiliate.campaign_name'),
          },
          {
            key: 'refer_code',
            label: $t('affiliate.refer_code'),
            sortable: true,
          },
          {
            key: 'referrals',
            label: $t('affiliate.total_referrals'),
            sortable: true,
          },
          {
            key: 'total_orders',
            label: $t('affiliate.total_orders'),
            sortable: true,
          },
          {
            key: 'campaign_hits',
            label: $t('affiliate.campaign_hits'),
            sortable: true,
          },
          {
            key: 'total_commission_amount',
            tooltip: $t('affiliate.available_commission_tooltip'),
            label: $t('affiliate.total_earned'),
            sortable: true,
            formatter: v => formatWithGBP(v),
          },
          {
            key: 'total_revenue',
            label: $t('affiliate.total_purchased'),
            sortable: true,
            formatter: v => formatWithGBP(v),
          },
          {
            key: 'created_at',
            label: $t('general.created_at'),
            sortable: true,
            formatter: v => formatLocalDateTime(v),
          },
        ]"
        :affiliateDetails="campaignTarget"
      />
    </b-modal>

    <UserDetailModal :detailTarget="detailTarget" :hideModal="hideModal" :user="detailTarget" :isAffiliate="true" />
  </card>
</template>
<script>
import routerMixin from '@/mixins/router-mixin'
import UserDetailModal from '@/components/Users/UserDetailModal.vue'
import AffiliateList from '@/components/Affiliates/AffiliateList.vue'
import AffiliateReferralList from '@/components/Affiliates/AffiliateReferralList.vue'
import AffiliateTransactionList from '@/components/Affiliates/AffiliateTransactionList.vue'
import AffiliateCampaignList from '@/components/Affiliates/AffiliateCampaignList.vue'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { floatAddition, formatWithGBP } from '@/utils/numberUtils'

export default {
  mixins: [
    routerMixin, // hasPermission
  ],
  components: {
    AffiliateList,
    UserDetailModal,
    AffiliateReferralList,
    AffiliateTransactionList,
    AffiliateCampaignList,
  },
  data() {
    return {
      detailTarget: null,
      referralsTarget: null,
      transactionTarget: null,
      campaignTarget: null,
      currency: 'GBP',
    }
  },
  methods: {
    viewDetails(obj) {
      this.detailTarget = obj
      this.$bvModal.show('user-details')
    },
    viewReferrals(obj) {
      this.referralsTarget = obj
      this.$bvModal.show('affiliate-referrals-modal')
    },
    viewTransactions(obj) {
      this.transactionTarget = obj
      this.$bvModal.show('affiliate-transactions-modal')
    },
    viewCampaigns(obj) {
      this.campaignTarget = obj
      this.$bvModal.show('affiliate-campaigns-modal')
    },
    hideModal() {
      this.detailTarget = null
      this.referralsTarget = null
      this.transactionTarget = null
      this.campaignTarget = null
    },
    formatWithGBP,
    formatLocalDateTime,
    floatAddition,
  },
}
</script>
<style>
#affiliate-referrals-modal .modal-dialog {
  width: 80% !important;
}
#affiliate-transactions-modal .modal-dialog {
  width: 80% !important;
}
#affiliate-campaigns-modal .modal-dialog {
  width: 90% !important;
  max-width: 1800px !import;
}
</style>
