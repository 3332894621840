<template>
  <b-modal
    lazy
    centered
    :id="id ? id : 'user-create'"
    :title="$t('user.create_user[0]')"
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    footer-class="py-2"
    body-class="py-0"
    @shown="onShown"
    @hidden="onHidden"
  >
    <b-form @submit.prevent="onSubmit" id="user-create-form">
      <b-form-group label-for="user-store" label-class="text-sm" class="m-0">
        <template #label>{{ $tc('general.store', 1) }}<span class="text-danger ml-1">*</span></template>
        <b-form-select id="user-store" value="1" required disabled>
          <b-form-select-option value="1">{{ $t('uk') }}</b-form-select-option>
          <!-- <b-form-select-option :selected="selfStore == 2" :value="2">{{ $t('address.mexico') }}</b-form-select-option> -->
        </b-form-select>
      </b-form-group>

      <b-form-group label-for="user-email" label-class="text-sm" class="mt-3 mb-0">
        <template #label>{{ $t('user.user_email_address') }}<span class="text-danger ml-1">*</span></template>
        <b-form-input id="user-email" v-model="form['email']" type="email" :placeholder="$t('input.enter_email')" required />
      </b-form-group>

      <b-form-group label-for="user-password" label-class="text-sm" class="mt-3 mb-0">
        <template #label>{{ $t('auth.set_password') }}<span class="text-danger ml-1">*</span></template>
        <b-form-input id="user-password" v-model="form['password']" :placeholder="$t('auth.set_password')" required />
      </b-form-group>

      <b-form-group label-for="user-firstname" label-class="text-sm" class="mt-3 mb-0">
        <template #label>{{ $t('user.first_name') }}<span class="text-danger ml-1">*</span></template>
        <b-form-input id="user-firstname" v-model="form['first_name']" :placeholder="$t('user.first_name')" required />
      </b-form-group>

      <b-form-group label-for="user-lastname" label-class="text-sm" class="mt-3 mb-0">
        <template #label>{{ $t('user.last_name') }}<span class="text-danger ml-1">*</span></template>
        <b-form-input id="user-lastname" v-model="form['last_name']" :placeholder="$t('user.last_name')" required />
      </b-form-group>

      <b-form-group v-if="hasPermission(152)" label-for="user-is-affiliate" label-class="text-sm" class="mt-3 mb-0">
        <template #label>{{ $t('user.is_affiliate') }}<span class="text-danger ml-1">*</span></template>
        <b-form-select id="user-is-affiliate" v-model="form['is_affiliate']" value="0" required>
          <b-form-select-option value="0">{{ $t('no') }}</b-form-select-option>
          <b-form-select-option value="1">{{ $t('yes') }}</b-form-select-option>
        </b-form-select>
      </b-form-group>
    </b-form>
    <template #modal-footer="{ hide }">
      <b-button variant="outline-secondary" @click="hide">{{ $t('action.cancel') }}</b-button>
      <b-button variant="outline-primary" type="submit" form="user-create-form">{{ $t('action.submit') }}</b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'
import routerMixin from '@/mixins/router-mixin'

export default {
  name: 'UserCreate',
  mixins: [
    routerMixin, // hasPermission
  ],
  props: {
    id: String,
    getList: Function,
  },
  computed: {
    ...mapGetters({ selfStore: 'store' }),
  },
  data() {
    return {
      form: { store_id: 1, email: null, password: null, first_name: null, last_name: null, is_affiliate: 0 },
    }
  },
  methods: {
    onShown(evt) {
      this.form.store_id = 1
    },
    onHidden(evt) {
      this.form = { store_id: 1, email: null, password: null, first_name: null, last_name: null, is_affiliate: 0 }
    },
    onSubmit(evt) {
      const params = {
        ...this.form,
        successMsg: this.$t('user.create_user[1]'),
        errorMsg: this.$t('user.create_user[2]'),
      }
      this.$store.dispatch('request/addUser', params).then(res => {
        if (res.status === 200) {
          this.$bvModal.hide('user-create')
          this.getList()
        }
      })
    },
  },
}
</script>
