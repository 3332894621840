<template>
  <div style="display: contents">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />

    <!-- Search Input -->
    <div v-if="!this.affiliateDetails" class="px-4 pt-3 pb-2 d-flex flex-row flex-wrap align-items-start">
      <b-form-select v-model="queries.store_id" :disabled="!allowMultiStores" class="mb-2 mr-2" style="width: 90px">
        <b-form-select-option selected disabled hidden :value="null">{{ $tc('general.store', 1) }}</b-form-select-option>
        <b-form-select-option :value="''">{{ $t('general.all') + ' ' + $tc('general.store', 2) }}</b-form-select-option>
        <b-form-select-option :value="1">{{ $t('uk') }}</b-form-select-option>
        <b-form-select-option :value="2">{{ $t('address.mexico') }}</b-form-select-option>
      </b-form-select>

      <!--<b-form-input v-model="queries.id" type="search" placeholder="User ID" @keyup.enter="onSearch" class="mb-2 mr-2" style="width: 100px" />-->

      <!--<b-form-input v-model="queries.affiliate_id" type="search" placeholder="User ID" @keyup.enter="onSearch" class="mb-2 mr-2" style="width: 100px" />-->

      <b-form-input
        v-model="queries.affiliate_name"
        type="search"
        :placeholder="$t('affiliate.referrer_username')"
        @keyup.enter="onSearch"
        class="mb-2 mr-2"
        style="width: 180px"
      />

      <b-form-select v-model="queries['status']" class="mb-2 mr-2" style="width: 90px">
        <b-form-select-option selected disabled hidden :value="null">{{ $t('general.status') }}</b-form-select-option>
        <b-form-select-option :value="''">{{ $t('general.all') }}</b-form-select-option>
        <b-form-select-option :value="0">{{ $t('affiliate.status_list[0]') }}</b-form-select-option>
        <b-form-select-option :value="1">{{ $t('affiliate.status_list[1]') }}</b-form-select-option>
        <b-form-select-option :value="-1">{{ $t('affiliate.status_list[2]') }}</b-form-select-option>
      </b-form-select>

      <date-range-picker
        v-model="dateRange"
        :locale-data="{ format: 'yyyy-mm-dd' }"
        :auto-apply="true"
        :show-dropdowns="true"
        :ranges="ranges"
        :max-date="maxDate"
        class="mb-2 mr-2"
        style="width: max-content; min-width: 220px"
      />

      <div class="d-flex flex-row flex-wrap align-items-center justify-content-xl-between flex-grow-1">
        <button-group :onSearch="onSearch" :onReset="onReset" class="mb-2 mr-2" />
        <slot name="create"></slot>
      </div>
    </div>

    <!-- Table -->
    <b-table
      :fields="fields"
      :items="users ? users.data : []"
      show-empty
      :empty-text="$t('notify.table_no_records')"
      small
      hover
      responsive
      head-variant="light"
      class="m-0 p-0"
    >
      <template #cell(affiliate_name)="{ value, item }">
        <template v-if="hasSlot('affiliate_name')"><slot name="affiliate_name" :user="item"></slot></template>
        <template v-else>{{ value }}</template>
      </template>
      <template #cell(status)="{ value }">
        <template v-if="hasSlot('status')"><slot name="status" :user="item"></slot></template>
        <AffiliateTransStatus :value="value" />
      </template>
      <template #cell(actions)="{ item }">
        <template v-if="hasSlot('status')"><slot name="status" :user="item"></slot></template>
        <template v-else>
          <b-button @click="onApprove(item)" :disabled="item.status != 0" variant="success"><b-icon-check class="mr-1" />{{ $t('action.approve') }}</b-button>
          <b-button @click="onReject(item)" :disabled="item.status != 0" variant="danger">{{ $t('action.reject') }}<b-icon-x class="ml-1" /></b-button>
        </template>
      </template>
    </b-table>

    <paginate v-if="users" :queries="queries" :fetcher="getList" :total="users.total" class="card-footer" />
    <b-modal
      lazy
      centered
      id="confirm"
      :title="confirmTarget ? $t(`transaction.${confirmTarget.isReject ? 'reject_' : 'approve_'}withdraw[1]`) : $t('action.confirm_action')"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="py-2"
      footer-class="py-2"
      body-class="py-0"
      dialog-class="w-50"
      :busy="isLoading"
      @hidden="hideConfirm"
    >
      <b-form v-if="confirmTarget" @submit.prevent="onSubmit" id="approve-withdraw-form">
        <p class="mt-2 mb-0">
          <span class="text-sm">{{ $t('transaction.transaction_id') }}:</span><span class="ml-3">{{ confirmTarget.id }}</span>
        </p>
        <p class="mt-2 mb-0">
          <span class="text-sm">{{ $tc('general.affiliate', 1) }}:</span><span class="ml-3">{{ confirmTarget.affiliate_name }}</span>
        </p>
        <p class="mt-2 mb-0">
          <span class="text-sm">{{ $tc('general.amount', 1) }}:</span><span class="ml-3">{{ formatWithGBP(confirmTarget.withdraw_amount) }}</span>
        </p>
        <p class="mt-2 mb-0">
          <span class="text-sm">{{ $t('general.status') }}:</span>
          <span class="ml-3">
            <AffiliateTransStatus :value="confirmTarget.status" />
            -->
            <AffiliateTransStatus :value="confirmTarget.isReject ? 2 : 1" />
          </span>
        </p>

        <!--<p class="mt-4 mb-0">{{ $t(`transaction.${confirmTarget.isReject ? 'reject_' : 'approve_'}withdraw[2]`) }}</p>-->

        <b-form-group class="mt-2 mb-0 mx-0 p-0">
          <template #label>
            {{ $tc('general.comment', 2)
            }}<!--<span v-if="confirmTarget.isReject" class="ml-1 text-danger">*</span>-->
          </template>
          <b-form-textarea v-model="confirmTarget['comment']" :required="confirmTarget.isReject ? true : false" :placeholder="$t('cashback.leave_comment')" />
        </b-form-group>
      </b-form>
      <template #modal-footer="{ hide }">
        <b-button variant="outline-secondary" @click="hide" :disabled="isLoading">{{ isLoading ? $t('status.loading') : $t('action.cancel') }}</b-button>
        <b-button variant="outline-primary" type="submit" form="approve-withdraw-form" :disabled="isLoading">{{ $t('action.submit') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import utilsMixin from '@/mixins/utils-mixin'
import { formatWithGBP } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'
import AffiliateTransStatus from '../AffiliateTransStatus.vue'
import axios from '@/plugins/axios'
import { API_LIST } from '@/utils/consts'
import { notifyError, notifySuccess } from '@/utils/index'
import { formatDayEndUtc, formatDayStartUtc, formatLocalDateTime, formatUtcDateTime, setupDateRanges } from '@/utils/dateTimeUtils'

export default {
  components: {
    AffiliateTransStatus,
  },
  name: 'AffiliateTransactionList',
  mixins: [utilsMixin],
  props: {
    affiliateDetails: Object,
    fields: Array,
    noInitOnMount: { type: Boolean, default: false },
  },
  data() {
    return {
      ranges: setupDateRanges(),
      maxDate: formatLocalDateTime(null, 'YYYY-MM-DD', true, true),
      isLoading: true,
      confirmTarget: null,
      initialParams: {},
      queries: {
        store_id: null,
        id: null,
        affiliate_id: null,
        affiliate_name: null,
        status: null,
        created_start: null,
        created_end: null,
        currency: 'GBP',
        is_affiliate: null,
        page: 1,
        perPage: 10,
      },
    }
  },
  computed: {
    ...mapGetters({
      users: 'request/affiliateTransactionList',
      allowMultiStores: 'allowMultiStores',
      selfStore: 'store',
    }),
    dateRange: {
      get() {
        return {
          startDate: formatLocalDateTime(this.queries.created_start, 1, false, true),
          endDate: formatLocalDateTime(this.queries.created_end, 1, false, true),
        }
      },
      set(newVal) {
        this.queries['created_start'] = formatDayStartUtc(newVal.startDate, 0, false, true)
        this.queries['created_end'] = formatDayEndUtc(newVal.endDate, 0, false, true)
        return newVal
      },
    },
  },
  methods: {
    getList() {
      this.isLoading = true
      const params = { ...this.queries }
      if (this.affiliateDetails) params['affiliate_id'] = this.affiliateDetails.id
      axios
        .get(API_LIST.get.affiliateTransactions, { params: params })
        .then(res => {
          this.$store.dispatch('request/mutateState', {
            property: 'affiliateTransactionList',
            with: res?.data?.data,
          })
        })
        .catch(err => notifyError(err, 'Failed to get affiliate transaction list'))
        .finally(() => (this.isLoading = false))
    },
    onApprove(obj) {
      this.confirmTarget = { ...obj, isReject: false }
      this.$bvModal.show('confirm')
    },
    onReject(obj) {
      this.confirmTarget = { ...obj, isReject: true }
      this.$bvModal.show('confirm')
    },
    onSearch() {
      this.queries['page'] = 1
      this.getList()
    },
    onReset() {
      for (const key in this.queries) {
        this.queries[key] = this.initialParams[key]
      }
      this.dateRange['startDate'] = null
      this.dateRange['endDate'] = null
      this.onSearch()
    },
    hideConfirm() {
      this.confirmTarget = null
    },
    onSubmit(evt) {
      if (this.confirmTarget?.id) {
        this.isLoading = true
        const params = {
          id: this.confirmTarget.id,
          action: this.confirmTarget.isReject ? -1 : 1,
          comment: this.confirmTarget.comment,
        }
        axios
          .post(API_LIST.post.affiliateTransactionAudit, params)
          .then(res => {
            notifySuccess(res, this.$t(`affiliate.trans_audit_notice[${this.confirmTarget.isReject ? 1 : 0}]`))
          })
          .catch(err => notifyError(err, this.$t('notify.unknown_err')))
          .finally(res => {
            this.isLoading = false
            this.getList()
            this.$bvModal.hide('confirm')
          })
      }
    },
    formatWithGBP,
  },
  created() {
    this.queries['store_id'] = this.allowMultiStores ? null : this.selfStore

    this.initialParams = { ...this.queries }

    if (!this.noInitOnMount) {
      this.getList()
    } else {
      this.isLoading = false
    }
  },
}
</script>
