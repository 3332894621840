<template>
  <b-modal
    lazy
    centered
    :id="id ? id : 'assign-affiliate'"
    :title="$t('user.assign_affiliate', { id: userDetails ? userDetails.id : '' })"
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    footer-class="py-2"
    body-class="py-0"
    @hidden="onHidden"
    @shown="onShown"
  >
    <b-form @submit.prevent="onSubmit" id="assign-affiliate-form" v-if="userDetails">
      <b-form-group label-for="assign-affiliate-name" label-class="text-sm" class="m-0">
        <template #label>{{ $t('general.username') }}</template>
        <b-form-input id="assign-affiliate-name" :value="userDetails.name" disabled />
      </b-form-group>
    </b-form>

    <template #modal-footer="{ hide }">
      <b-button variant="outline-secondary" @click="hide">{{ $t('action.cancel') }}</b-button>
      <b-button variant="outline-primary" type="submit" form="assign-affiliate-form" :disabled="!userDetails || !userDetails['id']">{{
        $t('action.submit')
      }}</b-button>
    </template>
  </b-modal>
</template>
<script>
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import axios from '@/plugins/axios'
import { API_LIST } from '@/utils/consts'
import { notifyError, notifySuccess } from '@/utils/index'
export default {
  name: 'UserAssignAffiliate',
  props: {
    id: String,
    userDetails: Object,
    getList: Function,
  },
  data() {
    return {}
  },
  computed: {},

  watch: {},
  methods: {
    onShown(evt) {},
    onHidden(evt) {
      this.form = {}
      this.$emit('update:userDetails', null)
    },
    onSubmit(evt) {
      const params = {
        user_id: this.userDetails.id,
      }
      axios
        .post(API_LIST.post.assignAffiliate, params)
        .then(res => {
          notifySuccess(res, this.$t('user.assign_affiliate_notice[0]'))
        })
        .catch(err => notifyError(err, this.$t('notify.unknown_err')))
        .finally(res => {
          this.getList()
          this.$bvModal.hide('user-assign-affiliate')
        })
    },
  },
}
</script>
