<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <ReportSearchInput
      type="affiliates"
      :queryParams="queryParams"
      showStoreDisabled
      showCurrencyDisabled
      showCurrency
      showDatePicker
      :onExport="onExport"
      :onSearch="onSearch"
      :onReset="onReset"
    />
    <b-table
      :fields="fields"
      :items="items"
      v-model="visibleRows"
      show-empty
      :empty-text="$t('notify.table_no_records')"
      hover
      responsive
      head-variant="light"
      sticky-header="100%"
      class="m-0 p-0"
    >
      <template #head()="{ label, field }"
        >{{ label }}<b-icon-question-circle-fill v-if="field.tooltip" class="ml-1" v-b-tooltip.hover.topright="field.tooltip"
      /></template>
      <template #bottom-row v-if="items.length > 0">
        <td class="table-b-table-default b-table-sticky-column">
          {{ $t('general.total') }}
        </td>
        <td colspan="1" class="text-right">
          <!--{{ sumTotal(visibleRows, 'affiliates') }}-->
        </td>
        <td colspan="1" class="text-right">
          {{ sumTotal(visibleRows, 'referrals') }}
        </td>
        <td colspan="1" class="text-right">
          <!--{{ sumTotal(visibleRows, 'total_referrals') }}-->
        </td>
        <td colspan="1" class="text-right">
          {{ sumTotal(visibleRows, 'orders') }}
        </td>
        <td v-for="(val, index) in sumKeys" :key="'bottom-cell-' + index" :class="`text-right`">
          {{ formatWithGBP(sumTotal(visibleRows, val.key)) }}
        </td>
      </template>
    </b-table>
    <div class="card-footer" v-if="items.length > 0"></div>
  </card>
</template>
<script>
import ReportSearchInput from '@/components/Reports/ReportSearchInput.vue'
import { exportAsExcel } from '@/utils/fileUtils'
import { formatWithGBP, sumTotal } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'
import axios from '@/plugins/axios'
import { API_LIST } from '@/utils/consts'
import { notifyError } from '@/utils/index'

export default {
  components: { ReportSearchInput },
  data() {
    return {
      isLoading: false,
      visibleRows: [],
      fields: [
        {
          key: 'date',
          label: this.$t('report.affiliates.date'),
          stickyColumn: true,
          sortable: true,
        },
        {
          key: 'affiliates',
          label: this.$t('report.affiliates.affiliates'),
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          key: 'referrals',
          label: this.$t('report.affiliates.referrals'),
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          key: 'total_referrals',
          label: this.$t('affiliate.total_referrals'),
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          key: 'orders',
          label: this.$t('report.affiliates.orders'),
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        // other keys will be added when component mounted
      ],
      queryParams: {
        store_id: 1,
        start: null,
        end: null,
        currency: 'GBP',
        timezone: null,
      },
      initialParams: {},
      sumKeys: [
        { key: 'purchased_amount', value: 'purchased_amount' },
        { key: 'commission', value: 'commission', tooltip: this.$t('affiliate.available_commission_tooltip') },
        { key: 'withdraw', value: 'withdraw' },
      ],
    }
  },
  computed: {
    ...mapGetters({ affiliatesReport: 'request/affiliatesReport' }),
    items() {
      return (
        this.affiliatesReport?.map(v => {
          if (v.raw_data) {
            const raw_data = JSON.parse(v.raw_data)
            v['affiliates'] = raw_data.affiliates || 0
            v['referrals'] = raw_data.referrals || 0
            v['total_referrals'] = raw_data.total_referrals || 0
            v['orders'] = raw_data.orders || 0
            v['purchased_amount'] = raw_data.purchased_amount || 0
            v['commission'] = raw_data.commission || 0
            v['withdraw'] = raw_data.withdraw || 0
            if (this.queryParams.timezone) v['date'] = this.$moment.utc(v.start_date).tz(this.queryParams.timezone).format('YYYY-MM-DD')
            else v['date'] = v.start_date.substring(0, 10)
          }
          return v
        }) ?? []
      )
    },
  },
  methods: {
    getAffiliatesReport() {
      this.isLoading = true
      axios
        .get(API_LIST.get.affiliateReport, { params: this.queryParams })
        .then(res => {
          this.$store.dispatch('request/mutateState', {
            property: 'affiliatesReport',
            with: res?.data?.data,
          })
        })
        .catch(err => notifyError(err, 'Failed to get affiliate report'))
        .finally(() => (this.isLoading = false))
    },
    onSearch() {
      this.getAffiliatesReport()
    },
    onReset() {
      this.queryParams = { ...this.initialParams }
      this.onSearch()
    },
    onExport() {
      if (this.items.length > 0) {
        const headings = ['date', 'affiliates', 'referrals', 'total_referrals', 'orders']
        const headingsFormat = [
          [
            this.$t('report.affiliates.date'),
            this.$t('report.affiliates.affiliates'),
            this.$t('report.affiliates.referrals'),
            this.$t('report.affiliates.total_referrals'),
            this.$t('report.affiliates.orders'),
          ],
        ]
        const bottomRow = {
          date: 'Total',
          /*affiliates: sumTotal(this.visibleRows, 'affiliates'),*/
          referrals: sumTotal(this.visibleRows, 'referrals'),
          /*total_referrals: sumTotal(this.visibleRows, 'total_referrals'),*/
          orders: sumTotal(this.visibleRows, 'orders'),
        }
        this.sumKeys.map(val => {
          headings.push(val.key)
          headingsFormat[0].push(this.$t('report.affiliates.' + val.value))
          bottomRow[val.key] = sumTotal(this.visibleRows, val.key)
        })
        const data = this.items.map(item => {
          delete item.id
          delete item.type
          delete item.currency_code
          delete item.created_at
          delete item.start_date
          delete item.end_date
          delete item.raw_data
          delete item.updated_at
          return item
        })
        data.push(bottomRow)
        return exportAsExcel(
          this.$t('report.affiliates.list_filename') +
            `_${this.queryParams.currency}_${this.queryParams.start}-${this.queryParams.end}_${this.queryParams.timezone}`, // filename
          null, // no need to fetch data from url because no pagination
          data, // pass data as params because no pagination
          this.items.length, // total
          null, // no pre-process
          headings,
          headingsFormat,
          bool => (this.isLoading = bool),
        )
      } else {
        this.$notify({
          group: 'root',
          type: 'error',
          text: this.$t('notify.no_records'),
        })
      }
    },
    formatWithGBP,
    sumTotal,
  },
  // queryParams will be initialized when ReportSearchInput created
  mounted() {
    this.sumKeys.forEach((val, i) => {
      this.fields.push({
        key: val.key,
        label: this.$t('report.affiliates.' + val.value),
        sortable: true,
        formatter: v => formatWithGBP(v),
        thClass: 'text-center',
        tdClass: 'text-right',
        tooltip: val.tooltip,
      })
    })
    this.initialParams = { ...this.queryParams }
    this.getAffiliatesReport()
  },
}
</script>
