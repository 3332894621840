<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <AffiliateCampaignList
      :fields="[
        {
          key: 'id',
          stickyColumn: true,
          label: $t('general.id'),
          sortable: true,
        },
        {
          key: 'affiliate_name',
          label: $t('affiliate.referrer_username'),
        },
        {
          key: 'title',
          label: $t('affiliate.campaign_name'),
        },
        {
          key: 'refer_code',
          label: $t('affiliate.refer_code'),
          sortable: true,
        },
        {
          key: 'referrals',
          label: $t('affiliate.total_referrals'),
          sortable: true,
        },
        {
          key: 'total_orders',
          label: $t('affiliate.total_orders'),
          sortable: true,
        },
        {
          key: 'campaign_hits',
          label: $t('affiliate.campaign_hits'),
          sortable: true,
        },
        {
          key: 'total_commission_amount',
          label: $t('affiliate.total_earned'),
          sortable: true,
          formatter: v => formatWithGBP(v),
          tooltip: $t('affiliate.available_commission_tooltip'),
        },
        {
          key: 'total_revenue',
          label: $t('affiliate.total_purchased'),
          sortable: true,
          formatter: v => formatWithGBP(v),
        },
        {
          key: 'created_at',
          label: $t('general.created_at'),
          sortable: true,
          formatter: v => formatLocalDateTime(v),
        },
      ]"
    >
      <template #referrals="{ user }">
        <span class="action" @click="viewReferrals({ title: user.title, refer_code: user.refer_code })">{{ user.referrals }}</span>
      </template>
    </AffiliateCampaignList>

    <b-modal
      lazy
      centered
      id="affiliate-referrals-modal"
      :title="`${$tc('affiliate.campaign', 1)} (${referralsTarget ? referralsTarget.title : ''}) ${$t('affiliate.referred_users')}`"
      title-class="w-150 d-flex justify-content-center align-self-center"
      header-class="py-2"
      body-class="pt-0"
      hide-footer
      @hidden="hideModal"
    >
      <AffiliateReferralList
        id="affiliate-referrals-modal"
        :fields="[
          { key: 'id', label: $tc('affiliate.referral_id'), sortable: true },
          {
            key: 'affiliate_name',
            label: $t('affiliate.referrer_username'),
            stickyColumn: true,
          },
          { key: 'campaign_name', label: $tc('affiliate.campaign', 1) },
          { key: 'user_name', label: $t('affiliate.referred_username') },
          { key: 'refer_code', label: $t('affiliate.refer_code') },
          {
            key: 'total_orders',
            label: $t('affiliate.total_orders'),
            sortable: true,
          },
          {
            key: 'total_revenue',
            label: $t('affiliate.total_purchased'),
            sortable: true,
            formatter: v => formatWithGBP(v),
          },
          {
            key: 'total_commission_amount',
            label: $t('affiliate.total_earned'),
            sortable: true,
            formatter: v => formatWithGBP(v),
            tooltip: $t('affiliate.available_commission_tooltip'),
          },
          {
            key: 'created_at',
            label: $t('user.registered_at'),
            sortable: true,
            formatter: v => formatLocalDateTime(v),
          },
        ]"
        :affiliateDetails="referralsTarget"
      />
    </b-modal>
  </card>
</template>
<script>
import AffiliateReferralList from '@/components/Affiliates/AffiliateReferralList.vue'
import AffiliateCampaignList from '@/components/Affiliates/AffiliateCampaignList.vue'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { formatWithGBP } from '@/utils/numberUtils'

export default {
  components: {
    AffiliateCampaignList,
    AffiliateReferralList,
  },
  data() {
    return {
      referralsTarget: null,
      currency: 'GBP',
    }
  },
  methods: {
    hideModal() {
      this.detailTarget = null
    },
    viewReferrals(obj) {
      this.referralsTarget = obj
      this.$bvModal.show('affiliate-referrals-modal')
    },
    formatLocalDateTime,
    formatWithGBP,
  },
}
</script>
<style>
#affiliate-referrals-modal .modal-dialog {
  width: 80% !important;
}
</style>
