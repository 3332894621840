<template>
  <b-form ref="compForm" @submit.prevent="onSubmit" id="competition-upsert-form" class="m-4 p-0 d-flex flex-column">
    <loading :active="isLoading" :can-cancel="true" :is-full-page="false" />

    <PrizeSetting
      :initVal="initVal"
      :form.sync="form"
      :noEditAfterPublish="noEditAfterPublish"
      :prodAmtValid.sync="prodAmtValid"
      :prodStoreValid.sync="prodStoreValid"
      :imgItems="imgItems"
      :stores="stores"
      :resetShortName="resetShortName">
      <template #default>
        <b-row cols="1" cols-xl="2" class="mt-4 pb-4 rounded bg-white">
          <b-col class="px-4 mt-4">
            <b-form-group label-for="comp-input-title" label-class="d-flex align-items-center form-label-h" class="m-0">
              <template #label>
                {{ $t('competition.comp_title') }}<span class="text-danger ml-1">*</span>
                <b-icon-question-circle-fill class="ml-1" v-b-tooltip.hover.topleft="$t('notify.translate_notice[0]')" />
                <icon-translate v-if="initVal && initVal.titleLang" />
              </template>
              <b-form-input id="comp-input-title" name="comp-input-title" v-model="form['title']" @change="onChangeTitle" type="text" :placeholder="$t('competition.comp_title')" required />
            </b-form-group>

            <b-form-group label-for="comp-input-store" class="d-inline-block w-50 pr-2 mt-4 mb-0">
              <template #label>
                {{ $tc('general.store', 1) }}<span class="text-danger ml-1">*</span>
                <b-icon-question-circle-fill class="ml-1" v-b-tooltip.hover.topleft="$t('competition.same_store_notice')" />
                <b-button v-if="isEditRoute && initVal.store_id == 1 && allowMultiStores" @click="toggleAllowMultiStores" variant="outline-primary" size="sm" class="ml-2 form-label-h">
                  <b-icon-pencil class="mr-1" />{{ $t('competition.toggle_multi_stores') }}
                </b-button>
              </template>
              <store-multiselect v-if="form.allow_multi_stores" :required="true" :disabled="!allowMultiStores && noEditAfterPublish" :store_arr.sync="store_arr" :defStore="initVal.store_id" />
              <b-form-select
                v-else
                id="comp-input-store"
                name="comp-input-store"
                v-model="form.store_id"
                :options="storeOpts"
                required
                :disabled="!allowMultiStores || noEditAfterPublish"
                @input="onChangeStore"
                :state="prodStoreValid" />
            </b-form-group>
            <b-form-group :label="$t('draw.draw_date')" label-class="form-label-required" label-for="comp-input-draw" class="d-inline-block w-50 pl-2 mt-4 mb-0">
              <b-form-input id="comp-input-draw" v-model="form.draw_date" @input="onInputDrawDate" :min="stores[0].end_time" type="datetime-local" :placeholder="$t('input.choose_date')" required />
            </b-form-group>
            <b-form-invalid-feedback v-if="!form.allow_multi_stores" :state="prodStoreValid">⚠ {{ $t('competition.same_store_notice') }}</b-form-invalid-feedback>

            <b-form-group :label="$t('competition.total_tix')" label-class="form-label-required" label-for="comp-input-stock" class="d-inline-block w-50 pr-2 mt-4 mb-0">
              <b-form-input
                id="comp-input-stock"
                name="comp-input-stock"
                v-model="form['stock_amount']"
                required
                :state="tixAmtValid"
                :disabled="noEditAfterPublish"
                number
                type="number"
                step="1"
                :min="form.max_tix_pp"
                :placeholder="$t('competition.total_tix')" />
            </b-form-group>
            <b-form-group :label="$t('competition.max_tix_pp')" label-class="form-label-required" label-for="comp-input-limit" class="d-inline-block w-50 pl-2 mt-4 mb-0">
              <b-form-input
                id="comp-input-limit"
                name="comp-input-limit"
                v-model="form['user_limit_amount']"
                required
                number
                type="number"
                step="1"
                min="0"
                :max="form.stock_amount"
                :state="tixAmtValid"
                :placeholder="$t('competition.max_tix_pp')" />
            </b-form-group>
            <b-form-invalid-feedback :state="tixAmtValid">{{ $t('competition.amount_validation') }}</b-form-invalid-feedback>
          </b-col>
          <b-col class="px-4 mt-4">
            <b-form-group label-for="comp-input-short" label-class="form-label-h" class="m-0">
              <template #label>
                {{ $t('competition.short_name') }}<span class="text-danger ml-1">*</span>
                <b-icon-question-circle-fill class="ml-1" v-b-tooltip.hover.left="$t('competition.short_name_notice[0]')" />
                <b-button v-if="isEditRoute" @click="confShortChangeable" variant="outline-primary" size="sm" class="ml-2"><b-icon-pencil /></b-button>
              </template>
              <b-form-input
                id="comp-input-short"
                name="comp-input-short"
                v-model="form.short_name"
                type="text"
                :placeholder="$t('competition.short_name_notice[1]')"
                required
                :disabled="!isShortChangeable" />
            </b-form-group>

            <b-form-group label="Generation Method" label-class="form-label-required" label-for="comp-input-generate" class="d-inline-block w-50 pr-2 mt-4 mb-0">
              <b-form-select
                id="comp-input-generate"
                v-model="form.generate_method"
                :options="[
                  { value: 'shuffle', text: $t('competition.generate_method[1]') },
                  { value: 'sequential', text: $t('competition.generate_method[2]') },
                ]"
                required
                :disabled="noEditAfterPublish" />
            </b-form-group>
            <b-form-group :label="$t('competition.draw_method[0]')" label-class="form-label-required" label-for="comp-input-method" class="d-inline-block w-50 pl-2 mt-4 mb-0">
              <b-form-select
                id="comp-input-method"
                v-model="form.draw_method"
                :options="[
                  { value: 'manual', text: $t('competition.draw_method[1]') },
                  { value: 'auto', text: $t('competition.draw_method[2]') },
                  { value: 'consolation', text: $t('competition.draw_method[3]') },
                ]"
                required
                :disabled="noEditAfterPublish" />
            </b-form-group>

            <b-form-group :label="$t('competition.live_winner_amt')" label-class="form-label-required" label-for="comp-input-ldwa" class="d-inline-block w-50 pr-2 mt-4 mb-0">
              <b-form-input
                id="comp-input-ldwa"
                name="comp-input-ldwa"
                v-model="form['live_winner_amt']"
                required
                number
                type="number"
                step="1"
                :min="1"
                :placeholder="$t('competition.live_winner_amt')" />
            </b-form-group>
            <!-- <CompetitionMultiPay v-if="($cookies.get('Store') == 1 || selfStore == 1) && form.store_id == 1" :enabled="status == 0 && isEditRoute" :form="form" /> -->
          </b-col>
        </b-row>
      </template>
    </PrizeSetting>

    <!-- Multi Stores Panel -->
    <b-row class="mt-4 rounded bg-white">
      <b-overlay :show="!prodStoreValid" variant="secondary" opacity="0.50" rounded="sm" class="w-100 m-0 p-0">
        <b-tabs
          v-model="currentTab"
          lazy
          small
          card
          class="w-100 m-0 p-0"
          active-nav-item-class="bg-white text-primary"
          nav-wrapper-class="bg-secondary"
          nav-class="text-lg font-weight-bold"
          ref="storeTabs">
          <template v-for="(store, index) in stores">
            <b-tab
              v-if="index === 0 || form.allow_multi_stores"
              :disabled="!allowMultiStores && store.value !== selfStore"
              :key="'store-tab-' + index"
              title-link-class="d-flex flex-row align-items-center"
              class="w-100 m-0 p-0">
              <template #title>
                {{ $tc('general.store', 1) + ' ' + store.text }}
                <CompetitionStatus :value="store.status" class="ml-2" />
              </template>
              <b-row cols="1" cols-xl="2" class="w-100 m-0 p-0 position-relative">
                <b-col class="mt-4 px-4 h-100" style="position: initial !important">
                  <QuizLookup
                    v-if="store.value == 1"
                    :initVal="initVal ? initVal.quiz : null"
                    :noEditAfterPublish="noEditAfterPublish"
                    :quiz_id.sync="form.quiz_id"
                    class="mb-4"
                    style="min-height: 126px" />
                  <b-form-group class="d-inline-block w-50 pr-2 mb-0">
                    <template #label>
                      {{ $t('competition.opening_date') }}
                      <span class="text-danger ml-1">*</span>
                      <b-button v-if="isEditRoute" @click="confStartChangeable(index)" variant="outline-primary" size="sm" class="ml-2 form-label-h"><b-icon-pencil /></b-button>
                    </template>
                    <b-form-input
                      :id="`comp-input-start-${index}`"
                      :name="`comp-input-start-${index}`"
                      v-model="store.start_time"
                      type="datetime-local"
                      :state="store.dateValid"
                      :max="store.end_time"
                      :placeholder="$t('input.choose_date')"
                      required
                      @input="dateValidation(store)"
                      :disabled="isEditRoute && !store.isStartChangeable" />
                  </b-form-group>
                  <b-form-group :label="$t('competition.closing_date')" label-class="form-label-required form-label-with-btn" class="d-inline-block w-50 pl-2 mb-0">
                    <b-form-input
                      :id="`comp-input-end-${index}`"
                      :name="`comp-input-end-${index}`"
                      v-model="store.end_time"
                      type="datetime-local"
                      :state="store.dateValid"
                      :min="store.start_time"
                      :placeholder="$t('input.choose_date')"
                      required
                      @input="onChangeEndTime($event, store)" />
                  </b-form-group>
                  <b-form-invalid-feedback :state="store.dateValid">{{ $t('competition.date_validation') }}</b-form-invalid-feedback>

                  <b-form-group :label="$t('competition.def_tix_pp')" label-class="form-label-required" class="d-inline-block w-50 pr-2 mt-4 mb-0">
                    <b-form-input
                      :id="`comp-input-def-${index}`"
                      :name="`comp-input-def-${index}`"
                      v-model="store['default_ticket_amount']"
                      number
                      type="number"
                      min="1"
                      :max="form.stock_amount"
                      step="1"
                      required
                      :state="store.amtValid"
                      :placeholder="$t('competition.def_tix_pp')"
                      @input="defAmtValidation(store)" />
                  </b-form-group>
                  <b-form-invalid-feedback :state="store.amtValid">{{ $t('competition.amount_validation') }}</b-form-invalid-feedback>

                  <b-form-group class="flex-grow-0 mt-4 mb-0" label-class="d-flex align-items-center">
                    <template #label>
                      {{ $t('competition.prize_description') }}<span class="text-danger ml-1">*</span>
                      <b-icon-question-circle-fill class="ml-2" v-b-tooltip.hover.topleft="$t('notify.translate_notice[0]')" />
                      <icon-translate v-if="initVal && initVal.descrLang" />
                    </template>
                    <b-form-textarea
                      :id="`comp-input-descr-${index}`"
                      :name="`comp-input-descr-${index}`"
                      v-model="store['description']"
                      required
                      @change="onChangeDescr(index, $event)"
                      :placeholder="$t('competition.prize_description')"
                      size="lg"
                      rows="8"
                      no-resize
                      style="font-size: 0.85rem" />
                  </b-form-group>
                  <Prices
                    :store.sync="store"
                    :index="index"
                    :isEditRoute="isEditRoute"
                    :confPriceChangeable="confPriceChangeable"
                    :priceValidation="priceValidation"
                    :class="`my-4 ${store.value == 1 ? '' : 'pb-6'}`" />
                  <FinancialSum v-if="store.value == 1" :competition="form" :store="store" class="border-top mt-4 pt-4" />
                </b-col>
                <b-col class="mt-4 px-4 d-flex flex-column">
                  <CompetitionImages
                    :title="form.title"
                    :initVal="initVal ? initVal.images : null"
                    :store="store"
                    :imgItems.sync="imgItems"
                    class="d-flex flex-column justify-content-start"
                    style="height: 420px" />
                  <CatalogsEdit :store.sync="store" class="d-flex flex-column justify-content-end h-100 mt-4" />

                  <!-- Buttons specified for one store -->
                  <div class="d-flex justify-content-end align-items-center my-4" style="column-gap: 8px; z-index: 2">
                    <CompetitionApprovalStatus v-if="store.value == 1 && [-1, 0, 1].includes(is_approval)" :value="is_approval" />
                    <b-button v-if="hasPermission(129) && store.value == 1 && (store.status == 0 || store.status == 1) && is_approval == 0" @click="confirmApproval" variant="primary" class="mx-0">
                      Approve
                    </b-button>
                    <b-button
                      v-if="(allowMultiStores || selfStore == 1) && store.value == 1 && (!isEditRoute || store.status == 0)"
                      id="preview-btn"
                      type="submit"
                      form="competition-upsert-form"
                      variant="success"
                      class="mx-0">
                      {{ $t('action.preview') }}
                    </b-button>
                    <b-button v-if="isEditRoute && store.status == 0" id="remove-btn" @click="confirmRemove(store.value)" variant="danger" class="mx-0">
                      {{ $t('action.remove') }}
                    </b-button>
                    <b-button v-if="store.status == 0 && (index == 0 || stores[0].status > 0)" id="publish-btn" type="submit" form="competition-upsert-form" variant="primary" class="mx-0">
                      {{ $t('action.publish') }}
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-tab>
          </template>
        </b-tabs>
      </b-overlay>
    </b-row>

    <div class="mt-4 row px-4 d-flex flex-row justify-content-end">
      <b-button variant="primary" id="save-btn" type="submit" form="competition-upsert-form">{{ $t('action.save') }}</b-button>
      <b-button variant="basic" @click="$router.back()">{{ $t('action.cancel') }}</b-button>
    </div>

    <b-modal
      lazy
      centered
      id="confirm"
      :title="confirmModal == 'publish' ? 'Publish Competition' : $t('action.confirm_action')"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="py-2"
      footer-class="py-2"
      body-class="py-0"
      dialog-class="w-50"
      v-model="showConfirm"
      @hidden="hideConfirm"
      :busy="isLoading">
      <p v-if="checked && notNaN(form.is_approval) && form.is_approval != (notNaN(is_approval) ? is_approval : -1)">
        The approval status will be changed from
        <CompetitionApprovalStatus :value="notNaN(is_approval) ? is_approval : -1" />
        to
        <CompetitionApprovalStatus :value="form.is_approval" />.
      </p>
      <p v-if="confirmMsg">{{ confirmMsg }}</p>
      <template v-if="stores[currentTab].value == 1 && ['save', 'publish', 'save-req', 'preview-req', 'publish-req'].includes(confirmModal)">
        <FinancialSum :competition="form" :store="stores[currentTab]" hideTips hideWarnings class="border-top pt-4 mt-4" />
        <div v-if="checkRaw.length > 0" class="position-relative">
          <p class="text-xs mt-2 mb-0 text-danger">⚠️ An approval is needed before publishing:</p>
          <p v-for="item in checkRaw" :key="item.key" class="text-xs text-danger my-0 ml-3">
            {{ item.description }} : {{ item.key == 'expected_profit_margin' ? item.value + '%' : formatWithCurrency(item.value, 'GBP') }}
          </p>
          <b-form-group
            v-if="
              (stores[currentTab].status == 0 && confirmModal.includes('publish') && !(is_approval == 1 && checkRes.length <= 0)) || (stores[currentTab].status == 1 && confirmModal.includes('save'))
            "
            label="Comment for Approval Request"
            class="mt-2">
            <b-form-textarea v-model="form['audit_comment']" placeholder="Leave a comment for the approval request" />
          </b-form-group>
          <CompetitionApprovalStatus :value="is_approval" class="position-absolute top-0 right-0" />
        </div>
      </template>
      <template #modal-footer="{ hide }">
        <b-button @click="hide" :disabled="isLoading" variant="secondary" class="px-4">Close</b-button>
        <b-button v-if="confirmModal == 'start-change'" :disabled="isLoading" @click="setStartChangeable" variant="primary" class="px-4">{{ $t('yes') }}</b-button>
        <b-button v-else-if="confirmModal == 'price-change'" :disabled="isLoading" @click="setPriceChangeable" variant="primary" class="px-4">{{ $t('yes') }}</b-button>
        <b-button v-else-if="confirmModal == 'short-change'" :disabled="isLoading" @click="setShortChangeable" variant="primary" class="px-4">{{ $t('yes') }}</b-button>
        <b-button v-else-if="confirmModal == 'remove'" :disabled="isLoading" @click="removeCompetition" variant="primary" class="px-4">{{ $t('yes') }}</b-button>
        <b-button v-else-if="confirmModal == 'preview-after-publish'" :disabled="isLoading" @click="previewCompetition" variant="primary" class="px-4">{{ $t('yes') }}</b-button>
        <b-button v-else-if="confirmModal == 'preview'" :disabled="isLoading" id="confirm-preview-btn" type="submit" form="competition-upsert-form" variant="primary" class="px-4">
          {{ $t('yes') }}
        </b-button>
        <b-button
          v-else-if="confirmModal.includes('publish') || confirmModal.includes('save') || confirmModal.includes('req')"
          :disabled="isLoading"
          id="confirm-btn"
          type="submit"
          form="competition-upsert-form"
          variant="primary"
          class="px-4">
          {{ $t('yes') }}
        </b-button>
      </template>
    </b-modal>

    <CompetitionApprovalLogs :competitionId="id ? String(id) : null" :formType="approvalFormType" :onHide="backToList" />
  </b-form>
</template>
<script>
import CompetitionImages from '@/components/Competitions/CompetitionImages.vue'
import PrizeSetting from '@/components/Competitions/PrizeSetting.vue'
import routerMixin from '@/mixins/router-mixin'
import storeMixin from '@/mixins/store-mixin'
import utilsMixin from '@/mixins/utils-mixin'
import axios from '@/plugins/axios'
import { AwsUpdate } from '@/store/services/aws'
import { API_LIST, PRICE_OPTIONS, STORE_TEMPLATE } from '@/utils/consts'
import { formatLocalDateTime, formatUtcDateTime } from '@/utils/dateTimeUtils'
import { getRandomStr, notifyError, notifySuccess } from '@/utils/index'
import { formatWithCurrency, notNaN } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'
import CatalogsEdit from './CatalogsEdit.vue'
import CompetitionApprovalLogs from './CompetitionApprovalLogs.vue'
import CompetitionApprovalStatus from './CompetitionApprovalStatus.vue'
import CompetitionStatus from './CompetitionStatus.vue'
import FinancialSum from './FinancialSum.vue'
import Prices from './Prices.vue'
import QuizLookup from './QuizLookup.vue'

export default {
  name: 'CompetitionUpsert',
  mixins: [utilsMixin, storeMixin, routerMixin],
  components: {
    CompetitionImages,
    CatalogsEdit,
    CompetitionApprovalLogs,
    CompetitionApprovalStatus,
    CompetitionStatus,
    QuizLookup,
    Prices,
    FinancialSum,
    PrizeSetting,
  },
  props: { initVal: Object, isEditRoute: Boolean },
  data() {
    const storeOpts = [
      { key: 1, value: 1, text: this.$t('uk') },
      { key: 2, value: 2, text: this.$t('address.mexico') },
    ]
    const selfStore = this.$store.getters['store']
    const defStore = storeOpts.find((x) => x.value == selfStore)
    const storeTemplate = { ...STORE_TEMPLATE, key: selfStore, value: selfStore, text: defStore?.text, store_id: selfStore, prices: PRICE_OPTIONS.reduce((arr, x) => arr.concat({ ...x }), []) }
    return {
      isLoading: false,
      id: this.$route.query.id,
      status: null,
      is_approval: null,
      approvalFormType: null,
      url: '',
      form: {
        title: '',
        short_name: '',
        description: '',
        draw_date: '',
        cost: null,
        generate_method: 'shuffle',
        draw_method: 'manual',
        stock_amount: null,
        user_limit_amount: null,
        live_winner_amt: 1,
        quiz_id: -1,
        liveDraw: [],
        instantWin: [],
        images: [],
        store_id: selfStore, // for single store
        allow_multi_stores: false,
      },
      store_arr: [defStore],
      stores: [storeTemplate],
      storeTemplate: { ...storeTemplate },
      imgItems: [],
      confirmMsg: '',
      confirmModal: '',
      isShortChangeable: false,
      prodAmtValid: null,
      prodStoreValid: null,
      currentTab: 0,
      checked: false,
      checkRaw: [],
      checkRes: [],
    }
  },
  computed: {
    ...mapGetters({ catalogList: 'request/catalogList' }),
    noEditAfterPublish() {
      return this.isEditRoute && this.status > 0
    },
    tixAmtValid() {
      return this.form.stock_amount && this.form.user_limit_amount ? parseInt(this.form.stock_amount) >= parseInt(this.form.user_limit_amount) : null
    },
    showQuiz() {
      return (this.allowMultiStores || this.selfStore == 1) && this.stores.findIndex((x) => x.store_id == 1) > -1
    },
    existingStores() {
      let tmp = []
      if (this.initVal) {
        // the first(default) store's data
        tmp[0] = ['store_id', 'status', 'description', 'default_ticket_amount', 'start_time', 'end_time'].reduce(
          (acc, key) => {
            acc[key] = this.initVal[key]
            return acc
          },
          { competition_id: this.initVal.id }
        )
        // the second store's data
        this.initVal.stores?.forEach?.((x) => tmp.push({ ...x }))
        tmp.forEach((store, i) => {
          store['start_time'] = formatLocalDateTime(store.start_time, 1, false, true)
          store['end_time'] = formatLocalDateTime(store.end_time, 1, false, true)
          store['imgFiles'] = []
          store['categories'] = this.initVal.catalogs?.reduce?.((arr, x) => (x.store_id == store.store_id ? arr.concat(x.id) : arr), []) || []
          store['catalogOpts'] =
            this.catalogList?.reduce?.(
              (arr, item) =>
                this.catalogFilter(item, store.store_id)
                  ? arr.concat({
                      id: item.id,
                      name: item.name,
                      is_active: item.is_active,
                      position: this.initVal.catalogs?.find(({ id }) => id == item.id)?.pivot?.position || 0,
                    })
                  : arr,
              []
            ) || []
          // prices
          const existingPrices = this.initVal.prices?.filter?.(({ store_id }) => store_id == store.store_id) || []
          store['prices'] = PRICE_OPTIONS.reduce((arr, opt) => {
            const existing = existingPrices.find((x) => x.type == opt.type)
            arr.push(
              existing
                ? {
                    id: existing.id,
                    type: existing.type,
                    status: existing.status,
                    amount: !existing.amount ? null : parseFloat(existing.amount),
                    start_at: formatLocalDateTime(existing.start_at, 1, false, true),
                    expired_at: formatLocalDateTime(existing.expired_at, 1, false, true),
                  }
                : { ...opt }
            )
            return arr
          }, [])
          this.defAmtValidation(store)
          this.dateValidation(store)
          this.priceValidation(store, i)
          store['isPriceChangeable'] = store.status <= 0
          store['isStartChangeable'] = store.status <= 0
        })
      }
      return tmp
    },
    showConfirm: {
      get() {
        return !!this.confirmModal
      },
      set: (val) => val,
    },
  },
  methods: {
    initForm() {
      if (this.initVal) {
        // edit or duplicate competition
        this.status = this.initVal.status
        this.is_approval = this.initVal.is_approval
        ;['id', 'status', 'store_id', 'description', 'draw_method', 'generate_method', 'quiz_id', 'short_name', 'stock_amount', 'title', 'user_limit_amount', 'cost', 'images'].forEach((key) => {
          this.form[key] = this.initVal[key]
        })
        this.form['draw_date'] = formatLocalDateTime(this.initVal.draw_date, 1, false, true)
        if (this.initVal.rewards?.length > 0) {
          this.initVal.rewards.forEach(({ product, type, id, ...rest }) => {
            const obj = { ...rest, ...product, reward_id: id, reward_type: type } // rename 'id' to 'reward_id', new 'id' will actually be 'product.id' if any
            obj['type'] = product ? product.type : rest.cash_period ? 'cash_multiple' : rest.cash_times >= 1 ? 'cash' : 'credit'
            if (!product) {
              obj['store_id'] = obj['amount'] = obj['stock_amount'] = obj['available_amount'] = 1
              obj[rest.cash_times >= 1 ? 'isCash' : 'isCredit'] = id // reward_id
            }
            this.form[type == 'consolation' ? 'instantWin' : 'liveDraw'].push(obj)
          })
          this.form['consolation_description'] = this.initVal.consolation_description
        }
        // multi stores
        this.form['currency'] = this.initVal.rewards?.[0]?.currency || this.getCurrencyByStoreId(this.initVal.store_id)
        this.form['allow_multi_stores'] = this.initVal.allow_multi_stores === 1
        const store_arr = [this.storeOpts.find((x) => x.value == this.initVal.store_id)]
        this.initVal.stores?.forEach?.((x) => store_arr.push(this.storeOpts.find((y) => y.value == x.store_id)))
        this.currentTab = this.allowMultiStores ? 0 : store_arr.findIndex((x) => x.value == this.selfStore)
        this.store_arr = store_arr // will trigger watcher -> initStores -> update this.stores -> update tabs
      } else {
        // create new competition
        this.form['currency'] = this.getCurrencyByStoreId(this.store_arr[0]?.value)
        this.currentTab = 0
        this.initStores(this.store_arr)
      }
    },
    initStores(newVal) {
      newVal.forEach((selected, i) => {
        const existing = this.existingStores.find((x) => x.store_id == selected.value)
        let tmp = {}
        if (!existing) {
          // ----> 1. when creating a new comp, ie. add a new default store tab
          tmp = {
            ...this.storeTemplate,
            ...selected,
            store_id: selected.value,
            competition_id: this.id,
            catalogOpts:
              this.catalogList?.reduce?.((arr, item) => (this.catalogFilter(item, selected.value) ? arr.concat({ id: item.id, position: 0, name: item.name, is_active: item.is_active }) : arr), []) ||
              [],
            prices: PRICE_OPTIONS.reduce((arr, item) => arr.concat({ ...item }), []),
          }
          // ----> 2. when adding a new store tab other than the default one
          if (i > 0) {
            const toCopy = ['start_time', 'end_time', 'description', 'default_ticket_amount']
            toCopy.forEach((key) => (tmp[key] = this.stores[0][key] ?? null))
            const imgs = this.imgItems.reduce((arr, item) => (item.store_id == this.stores[0].store_id && item.uploaded ? arr.concat({ ...item, id: null, store_id: selected.value }) : arr), [])
            this.imgItems = this.imgItems.concat(imgs)
          }
          this.defAmtValidation(tmp)
          this.dateValidation(tmp)
          this.priceValidation(tmp, i)
        } else {
          // ----> 3️. when editing an existing comp, map existing data to store tab
          for (const k in selected) {
            tmp[k] = selected[k]
          }
          for (const k in existing) {
            tmp[k] = existing[k]
          }
          if (this.stores[i]?.imgFiles?.length > 0) {
            tmp['imgFiles'] = this.stores[i].imgFiles
          }
        }
        this.stores.splice(i, 1, tmp)
      })
      // ----> 4. when removing a store tab
      const diff = this.stores.length - newVal.length
      if (diff > 0) {
        let tmp = this.imgItems
        for (let i = 0; i < diff; i++) {
          tmp = tmp.filter(({ store_id }) => store_id !== this.stores[newVal.length + i].store_id)
          this.stores.splice(newVal.length + i, 1)
        }
        this.imgItems = tmp
      }
    },
    onChangeStore(evt) {
      const tmp = this.storeOpts.find(({ value }) => value == evt)
      this.store_arr = [tmp] // will trigger watcher -> initStores -> update this.stores -> update tabs
    },
    toggleAllowMultiStores(evt) {
      if (this.form.allow_multi_stores) {
        this.form.allow_multi_stores = false // from multi to single
        this.onChangeStore(this.store_arr[0].value) // make store_arr contain one store (ie. the first store)
      } else {
        this.form.allow_multi_stores = true // from single to multi
      }
    },
    dateValidation(store) {
      let bool = null
      if (store.start_time && store.end_time) {
        bool = this.compareTime(store.end_time, store.start_time)
        if (bool && this.stores.length > 1) {
          bool = this.compareTime(this.stores[0].end_time, this.stores[1].end_time, true)
          if (bool) {
            bool = this.compareTime(this.stores[1].start_time, this.stores[0].start_time, true)
          }
        }
      }
      store['dateValid'] = bool
    },
    defAmtValidation(store) {
      store['amtValid'] = store.default_ticket_amount && this.form.stock_amount ? store.default_ticket_amount <= this.form.stock_amount : null
    },
    priceValidation(store, index) {
      const normal = store.prices.find(({ type }) => type === 0)?.amount
      const sales = store.prices.find(({ type }) => type === 1)?.amount
      store['priceValid'] = !sales ? null : !normal ? (this.currentTab == index ? null : false) : normal >= sales
    },
    resetShortName(title, end_time) {
      if (!this.isEditRoute) {
        const preFix = title?.match(/\s*(\w+\s*\w+)/)?.[0]?.replace(' ', '-') || ''
        const suffix = end_time ? '-' + this.$moment.utc(end_time).format('YYYY-MMM') + '-' + getRandomStr(2) : ''
        this.form['short_name'] = (preFix + suffix).toLowerCase()
      }
    },
    onChangeTitle(evt) {
      this.resetShortName(this.form.title, this.stores[0].end_time)
    },
    onChangeDescr(index, evt) {
      if (index == 0) {
        this.form.description = evt
      } // sync default store's description to the form
    },
    onInputDrawDate(evt) {
      const val = this.$moment(evt).subtract(30, 'm').format('YYYY-MM-DDTHH:mm:ss')
      this.stores.forEach((store) => {
        store['end_time'] = val
        this.onChangeEndTime(val, store)
      })
    },
    onChangeEndTime(evt, store) {
      this.resetShortName(this.form.title, evt)
      this.dateValidation(store)
    },
    /**
     * form validation - audit check - upload images - format form data - post upsert api
     */
    onSubmit(evt) {
      // form validation: must select a product; product amt, tickets amt, date and image must be valid,
      let invalid = !this.prodStoreValid || this.prodAmtValid == false || !this.tixAmtValid || this.imageSizeValid === false || (this.showQuiz && this.form.quiz_id < 0) || this.imgItems.length < 1
      console.log('### 1 ', this.prodStoreValid, this.prodAmtValid, this.tixAmtValid, this.imageSizeValid, this.showQuiz, this.form.quiz_id, this.imgItems.length)
      this.stores.forEach((store, i) => {
        const bool = !store.description || !store.dateValid || !store.amtValid || store.priceValid == false
        console.log('### 2 ', invalid, bool)
        invalid = invalid || bool
        if (bool && this.currentTab !== i) {
          this.currentTab = i
        }
      })
      console.log('### 3 ', invalid)
      if (invalid) {
        evt.preventDefault()
        this.$notify({ group: 'root', type: 'warn', text: this.$t('notify.input_err') })
        return
      }
      // preview-btn, publish-btn, save-btn, confirm-save-btn, confirm-preview-btn, confirm-publish-btn
      if (this.stores[this.currentTab].value == 2 || this.checked || this.stores[this.currentTab].status > 1) {
        this.uploadImgUpsertComp()
      } else {
        // store == 1, checked == false
        const noAuditLog = !this.isEditRoute || (this.stores[this.currentTab].status == 0 && this.is_approval == -1)
        if (noAuditLog && ['save-btn', 'confirm-preview-btn'].includes(evt.submitter.id)) {
          this.uploadImgUpsertComp()
        } else if (noAuditLog && evt.submitter.id == 'preview-btn') {
          evt.preventDefault()
          this.confirmMsg = this.$t('competition.preview_notice[0]', { title: this.form.title })
          this.confirmModal = 'preview'
          return
        } else {
          evt.preventDefault() // check if approval requirement is triggered
          this.auditCheck(evt.submitter.id)
          return
        }
      }
    },
    auditCheck(button) {
      this.isLoading = true
      const params = {
        prices: this.stores
          .find((x) => x.store_id == 1)
          ?.prices?.reduce?.(
            (arr, price) =>
              !!price.amount || (price.type == 0 && price.amount == 0)
                ? arr.concat({
                    id: price.id,
                    competition_id: this.isEditRoute ? this.id : null,
                    store_id: 1,
                    currency_code: 'GBP',
                    status: price.status,
                    type: price.type,
                    amount: price.amount,
                    start_at: price.type == 0 ? null : formatUtcDateTime(price.start_at),
                    expired_at: price.type == 0 ? null : formatUtcDateTime(price.expired_at),
                  })
                : arr,
            []
          ),
        cost: this.form['total_cost'],
        expected_profit_margin: this.form['expected_profit_margin'],
        stock_amount: this.form.stock_amount,
      }
      if (this.isEditRoute) params['competition_id'] = this.id
      return axios
        .post(API_LIST.post.approvalCheck, params)
        .then((res) => {
          this.checked = res?.status == 200 ? button || true : false
          this.checkRaw = res?.data?.data || []
          this.checkRes = res?.data?.data?.filter((x) => x.updated) || []
          const hasTrigger = this.checkRaw.length > 0
          const hasUpdated = this.checkRes.length > 0
          const targetStore = this.stores[this.currentTab]
          if (['save-btn', 'preview-btn'].includes(button)) {
            if (!hasTrigger) {
              if (targetStore.status == 0) {
                this.form['is_approval'] = -1
                this.confirmMsg = 'The previous approval request, if any, will be deprecated. '
                this.confirmModal = button.replace('btn', 'req')
              } else if (targetStore.status == 1) {
                this.form['is_approval'] = this.is_approval == 1 ? 1 : -1
                this.confirmModal = button.replace('-btn', '')
              }
              this.confirmMsg += `Are you sure to save changes of ${this.form.title} (ID: ${this.id ?? 'NO DATA'}, Store: UK)${button == 'preview-btn' ? ' and redirect to the preview page' : ''}?`
            } else if (hasUpdated) {
              if (targetStore.status == 1) {
                this.form['is_approval'] = 0
              } else if (targetStore.status == 0) {
                this.form['is_approval'] = -1
              }
              this.confirmMsg = 'The previous approval request, if any, will be deprecated. '
              this.confirmModal = button.replace('btn', 'req')
            } else if (button == 'save-btn') {
              this.uploadImgUpsertComp()
              return
            } else if (button == 'preview-btn') {
              this.confirmMsg = this.$t('competition.preview_notice[0]', { title: this.form.title })
              this.confirmModal = 'preview'
            }
          } else if (button == 'publish-btn') {
            if (!hasTrigger) {
              this.form['is_approval'] = -1
              this.confirmMsg = this.$t('competition.publish_notice[0]', [this.form.title, this.id ?? 'NO DATA', targetStore.text])
              this.confirmModal = 'publish'
            } else if (hasUpdated) {
              this.form['is_approval'] = 0
              this.confirmMsg = 'A new request for approval will be submitted. '
              this.confirmModal = 'publish-req'
            } else if (this.is_approval == 1) {
              this.confirmMsg = this.$t('competition.publish_notice[0]', [this.form.title, this.id ?? 'NO DATA', targetStore.text])
              this.confirmModal = 'publish'
            } else if (this.is_approval == 0) {
              this.confirmMsg = 'This competition is currently awaiting approval. Kindly get in touch with the concerned personnel for further updates.'
              this.confirmModal = 'waiting-approval'
            }
          }
          if (this.confirmModal) {
            this.$bvModal.show('confirm')
          }
        })
        .catch((err) => notifyError(err, this.$t('notify.unknown_err')))
        .finally(() => (this.isLoading = false))
    },
    uploadImgUpsertComp() {
      const needPublish = this.confirmModal?.includes('publish')
      const needPreview = this.confirmModal?.includes('preview')
      this.$bvModal.hide('confirm')
      this.isLoading = true
      try {
        const tmp = this.imgItems.filter((x) => this.store_arr.map((y, i) => (i == 0 || this.form.allow_multi_stores ? y.value : this.form.store_id)).indexOf(x.store_id) > -1)
        let cnt = 0
        const newArr = []
        tmp.forEach(async (img) => {
          if (img.id) {
            // no need to upload existing images, update the position only
            newArr.push({ ...this.form.images.find((x) => x.id == img.id), position: img.position })
          } else if (img.product_id || img.uploaded) {
            // no need to upload images from product
            newArr.push({ code: 'image', value: img.src, position: img.position, store_id: img.store_id })
          } else if (!img.uploaded && img.file) {
            await AwsUpdate('rkings', img.file, img.name).then((res) => {
              img.src = res.Location
              img.uploaded = true
              newArr.push({ code: 'image', value: res.Location, position: img.position, store_id: img.store_id })
            })
          }
          cnt += 1
          if (tmp.length === cnt) {
            this.form.images = newArr
            return this.upsertComp(needPublish, needPreview)
          }
        })
      } catch (error) {
        this.isLoading = false
        notifyError(error, this.$t('notify.unknown_err'))
      }
    },
    upsertComp(needPublish, needPreview) {
      const params = [
        'consolation_description',
        'title',
        'short_name',
        'generate_method',
        'draw_method',
        'live_winner_amt',
        'stock_amount',
        'user_limit_amount',
        'description',
        'images',
        'audit_comment',
      ].reduce(
        (obj, key) => {
          obj[key] = this.form[key]
          return obj
        },
        {
          id: this.isEditRoute ? this.id : undefined,
          quiz_id: this.showQuiz && this.form.quiz_id > 0 ? this.form.quiz_id : 1528,
          store_id: this.stores[0].store_id,
          draw_date: formatUtcDateTime(this.form.draw_date),
          allow_multi_stores: this.form.allow_multi_stores ? 1 : 0,
          start_time: formatUtcDateTime(this.stores[0].start_time),
          end_time: formatUtcDateTime(this.stores[0].end_time),
          default_ticket_amount: this.stores[0].default_ticket_amount,
          rewards: [],
          categories: [],
          prices: [],
          stores: [],
        }
      )
      this.form.liveDraw?.forEach?.((item) => {
        params['rewards'].push(
          (item.id ? ['amount'] : ['store_id', 'cost', 'cash_period', 'cash_times']).concat('must_go', 'alternative_cash_amount', 'alternative_credit_amount').reduce(
            (obj, key) => {
              obj[key] = !key.includes('alternative') || item[key] > 0 ? item[key] : 0
              return obj
            },
            { type: 'winner', id: item.reward_id, product_id: item.id || undefined }
          )
        )
      })
      if (this.form.instantWin?.length > 0) {
        this.form.instantWin.forEach((item) =>
          params['rewards'].push(
            (item.id ? ['amount'] : ['store_id', 'cost', 'cash_period', 'cash_times']).concat('ticket_number', 'must_go', 'alternative_cash_amount', 'alternative_credit_amount').reduce(
              (obj, key) => {
                obj[key] = !key.includes('alternative') || item[key] > 0 ? item[key] : 0
                return obj
              },
              { type: 'consolation', id: item.reward_id, product_id: item.id || undefined }
            )
          )
        )
        params['consolation_description'] = this.form.consolation_description
      }
      this.stores.forEach((store, index) => {
        if (index == 0 || this.form.allow_multi_stores) {
          store.prices.forEach((price) => {
            if (!!price.amount || (price.type == 0 && price.amount == 0)) {
              // status 0 no display, 1 display
              const priceObj = { store_id: store.store_id, currency_code: ['GBP', 'MXN'][store.value - 1], status: price.status, type: price.type, amount: price.amount }
              if (this.isEditRoute) {
                priceObj['competition_id'] = this.id
              }
              if (price.id) {
                priceObj['id'] = price.id
              }
              if (price.type !== 0) {
                priceObj['start_at'] = formatUtcDateTime(price.start_at)
                priceObj['expired_at'] = formatUtcDateTime(price.expired_at)
              }
              params['prices'].push(priceObj)
            }
          })
          store.categories.forEach((cateId) => {
            const cateOpt = store.catalogOpts.find(({ id }) => id == cateId)
            params['categories'].push({
              store_id: store.store_id,
              id: cateOpt?.id || cateId,
              position: parseInt(cateOpt?.position || this.initVal?.catalogs?.find?.(({ id }) => id == cateId)?.pivot?.position || 0),
            })
          })
        }
        if (index > 0 && this.form.allow_multi_stores) {
          const storeObj = ['status', 'description', 'default_ticket_amount', 'start_time', 'end_time'].reduce(
            (obj, key) => {
              obj[key] = key.includes('_time') ? formatUtcDateTime(store[key]) : store[key]
              return obj
            },
            store.id ? { id: store.id } : { store_id: store.store_id }
          )
          params['stores'].push(storeObj)
        }
      })
      if (this.checked && notNaN(this.is_approval) && this.form.is_approval != this.is_approval) {
        params['is_approval'] = this.form.is_approval
      }
      this.isLoading = true
      axios
        .post(this.isEditRoute ? API_LIST.post.updateCompetition : API_LIST.post.createCompetition, params)
        .then((res) => {
          notifySuccess(res, this.$t(`competition.upsert_notice[${res?.status === 200 ? 0 : 1}]`, { action: this.isEditRoute ? this.$t('action.update') : this.$t('action.create') }))
          if (res?.status === 200) {
            this.id = res.data?.data?.id || this.id
            this.status = this.isEditRoute ? res.data?.data?.status ?? this.status : 0
            this.url = res.data?.data?.url
            if (needPublish) {
              this.publishCompetition(res.data?.data?.id)
              return
            }
            if (needPreview) {
              this.previewCompetition()
              return
            }
            if (!this.isEditRoute && this.selfStore == 2) {
              this.switchLang()
            }
            this.backToList()
          }
          this.isLoading = false
        })
        .catch((err) => {
          notifyError(err, this.$t('competition.upsert_notice[1]', { action: this.isEditRoute ? this.$t('action.update') : this.$t('action.create') }))
          this.isLoading = false
        })
    },
    confShortChangeable() {
      if (this.isShortChangeable) {
        this.isShortChangeable = false
      } else {
        this.confirmMsg = this.$t('competition.short_name_notice[2]')
        this.confirmModal = 'short-change'
      }
    },
    setShortChangeable() {
      this.isShortChangeable = true
      this.$bvModal.hide('confirm')
    },
    confStartChangeable(i) {
      if (this.stores[i].isStartChangeable) {
        this.stores[i].isStartChangeable = false
      } else {
        this.confirmMsg = this.$t('competition.time_change_notice')
        this.confirmModal = 'start-change'
      }
    },
    setStartChangeable() {
      this.stores[this.currentTab].isStartChangeable = true
      this.$bvModal.hide('confirm')
    },
    confPriceChangeable(i) {
      if (this.stores[i].isPriceChangeable) {
        this.stores[i].isPriceChangeable = false
      } else {
        this.confirmMsg = this.$t('competition.price_notice')
        this.confirmModal = 'price-change'
      }
    },
    setPriceChangeable() {
      this.stores[this.currentTab].isPriceChangeable = true
      this.$bvModal.hide('confirm')
    },
    confirmRemove(store_id) {
      this.confirmMsg = this.$t('competition.remove_notice[0]', [this.form.title, this.id, this.storeOpts.find((x) => x.value == store_id).text])
      this.confirmModal = 'remove'
    },
    removeCompetition() {
      const params = { id: this.id, successMsg: this.$t('competition.remove_notice[1]'), errorMsg: this.$t('competition.remove_notice[2]') }
      if (this.currentTab == 0) {
        params['status'] = -1 // is first store
      } else {
        params['stores'] = [{ id: this.stores[this.currentTab].id, status: -1, store_id: this.stores[this.currentTab].store_id }]
      }
      this.$store.dispatch('request/updateCompetition', params).then((res) => {
        if (res.status === 200) this.backToList()
      })
    },
    publishCompetition(id) {
      const targetStore = this.stores[this.currentTab].value
      axios
        .post(API_LIST.post.publishCompetition, { id, store_id: targetStore, audit_comment: this.form['audit_comment'] })
        .then((res) => {
          notifySuccess(res, this.$t(`competition.publish_notice[${res?.status === 200 ? 1 : 2}]`))
          if (res?.status === 200) {
            if (targetStore == 1) {
              // only uk store can preview competition
              this.url = res.data?.data?.url
              this.confirmMsg = this.$t('competition.preview_notice[1]')
              this.confirmModal = 'preview-after-publish'
            } else {
              this.backToList()
            }
          }
        })
        .catch((err) => {
          if (err?.response?.status == 400 && err.response?.data?.data?.is_approval == 0) {
            this.$notify({ group: 'root', type: 'warn', text: 'An approval request has been submitted. The competition is waiting for approval.' })
            this.backToList()
          } else {
            notifyError(err, this.$t(`competition.publish_notice[2]`))
          }
        })
        .finally(() => (this.isLoading = false))
    },
    previewCompetition() {
      const link = document.createElement('a')
      link.href = this.url + (this.status == 0 && this.url.indexOf('token=') ? '&ticketsLeft=' + this.form.stock_amount : '')
      link.setAttribute('target', `_blank`)
      document.body.appendChild(link)
      link.click()
      this.backToList()
    },
    hideConfirm() {
      if (this.confirmModal == 'preview-after-publish') {
        this.backToList()
        return
      }
      this.checked = false
      this.confirmMsg = ''
      this.confirmModal = ''
    },
    switchLang() {
      const newVal = this.$i18n.availableLocales.find((x) => x !== this.$i18n.locale)
      this.$i18n.locale = newVal
      this.$moment.locale(newVal)
      localStorage.setItem('lang', newVal)
    },
    backToList() {
      this.$router.push({ path: '/competitions/competition-list', query: '' })
    },
    confirmApproval() {
      this.approvalFormType = 'approval'
      this.$bvModal.show('comp-approval-logs')
    },
    formatWithCurrency,
    notNaN,
    catalogFilter(catalog, targetStore) {
      return catalog.store_id == targetStore && !(catalog.store_id == 1 && catalog.short_name == 'home')
    },
  },
  watch: {
    store_arr(newVal) {
      this.form['store_id'] = newVal[0]?.value
      this.initStores(newVal)
    },
  },
  created() {
    this.storeTemplate['catalogOpts'] =
      this.catalogList?.reduce?.((arr, x) => (this.catalogFilter(x, this.selfStore) ? arr.concat({ id: x.id, position: 0, name: x.name, is_active: x.is_active }) : arr), []) || []
    this.initForm()
  },
}
</script>
<style scoped>
::v-deep .modal-dialog {
  width: fit-content;
  min-width: 45%;
  max-width: 65%;
}

@media screen and (max-width: 1024px) {
  ::v-deep .modal-dialog {
    min-width: 60%;
    max-width: 80%;
  }
}
</style>
