var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{staticClass:"table-custom",attrs:{"body-classes":"p-0 d-flex flex-column"}},[_c('AffiliateCampaignList',{attrs:{"fields":[
      {
        key: 'id',
        stickyColumn: true,
        label: _vm.$t('general.id'),
        sortable: true,
      },
      {
        key: 'affiliate_name',
        label: _vm.$t('affiliate.referrer_username'),
      },
      {
        key: 'title',
        label: _vm.$t('affiliate.campaign_name'),
      },
      {
        key: 'refer_code',
        label: _vm.$t('affiliate.refer_code'),
        sortable: true,
      },
      {
        key: 'referrals',
        label: _vm.$t('affiliate.total_referrals'),
        sortable: true,
      },
      {
        key: 'total_orders',
        label: _vm.$t('affiliate.total_orders'),
        sortable: true,
      },
      {
        key: 'campaign_hits',
        label: _vm.$t('affiliate.campaign_hits'),
        sortable: true,
      },
      {
        key: 'total_commission_amount',
        label: _vm.$t('affiliate.total_earned'),
        sortable: true,
        formatter: function (v) { return _vm.formatWithGBP(v); },
        tooltip: _vm.$t('affiliate.available_commission_tooltip'),
      },
      {
        key: 'total_revenue',
        label: _vm.$t('affiliate.total_purchased'),
        sortable: true,
        formatter: function (v) { return _vm.formatWithGBP(v); },
      },
      {
        key: 'created_at',
        label: _vm.$t('general.created_at'),
        sortable: true,
        formatter: function (v) { return _vm.formatLocalDateTime(v); },
      } ]},scopedSlots:_vm._u([{key:"referrals",fn:function(ref){
    var user = ref.user;
return [_c('span',{staticClass:"action",on:{"click":function($event){return _vm.viewReferrals({ title: user.title, refer_code: user.refer_code })}}},[_vm._v(_vm._s(user.referrals))])]}}])}),_c('b-modal',{attrs:{"lazy":"","centered":"","id":"affiliate-referrals-modal","title":((_vm.$tc('affiliate.campaign', 1)) + " (" + (_vm.referralsTarget ? _vm.referralsTarget.title : '') + ") " + (_vm.$t('affiliate.referred_users'))),"title-class":"w-150 d-flex justify-content-center align-self-center","header-class":"py-2","body-class":"pt-0","hide-footer":""},on:{"hidden":_vm.hideModal}},[_c('AffiliateReferralList',{attrs:{"id":"affiliate-referrals-modal","fields":[
        { key: 'id', label: _vm.$tc('affiliate.referral_id'), sortable: true },
        {
          key: 'affiliate_name',
          label: _vm.$t('affiliate.referrer_username'),
          stickyColumn: true,
        },
        { key: 'campaign_name', label: _vm.$tc('affiliate.campaign', 1) },
        { key: 'user_name', label: _vm.$t('affiliate.referred_username') },
        { key: 'refer_code', label: _vm.$t('affiliate.refer_code') },
        {
          key: 'total_orders',
          label: _vm.$t('affiliate.total_orders'),
          sortable: true,
        },
        {
          key: 'total_revenue',
          label: _vm.$t('affiliate.total_purchased'),
          sortable: true,
          formatter: function (v) { return _vm.formatWithGBP(v); },
        },
        {
          key: 'total_commission_amount',
          label: _vm.$t('affiliate.total_earned'),
          sortable: true,
          formatter: function (v) { return _vm.formatWithGBP(v); },
          tooltip: _vm.$t('affiliate.available_commission_tooltip'),
        },
        {
          key: 'created_at',
          label: _vm.$t('user.registered_at'),
          sortable: true,
          formatter: function (v) { return _vm.formatLocalDateTime(v); },
        } ],"affiliateDetails":_vm.referralsTarget}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }