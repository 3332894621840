var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rounded bg-white p-4",staticStyle:{"margin-left":"-16px","margin-right":"-16px"}},[_c('div',{staticClass:"w-100 d-flex flex-row align-items-center"},[_vm._m(0),_c('b-form-select',{staticClass:"ml-2",staticStyle:{"width":"200px"},attrs:{"options":[
        { value: null, text: 'Select Prize Type', disabled: true },
        { value: 'product', text: 'Product' },
        { value: 'cash', text: _vm.$t('product.types.cash') },
        { value: 'cash_multiple', text: _vm.$t('product.types.cash_multiple') },
        { value: 'credit', text: _vm.$t('product.types.credit') } ]},on:{"change":_vm.onChangeType},model:{value:(_vm.rwdTypeInp),callback:function ($$v) {_vm.rwdTypeInp=$$v},expression:"rwdTypeInp"}}),_c('div',{staticClass:"ml-auto"},[_c('span',{staticClass:"font-weight-normal text-success"},[_vm._v(_vm._s(_vm.$t('general.total'))+": "+_vm._s(_vm.allCosts))])])],1),_c('b-table',{staticClass:"mt-3 mb-0 mx-0 p-0",attrs:{"show-empty":"","small":"","hover":"","responsive":"","sticky-header":"360px","head-variant":"light","tbody-class":"h-100","empty-text":'⚠ ' + _vm.$t('competition.min_1_prize'),"fields":_vm.liveDrawFields,"items":_vm.form['liveDraw']},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
      var label = ref.label;
      var field = ref.field;
return [_vm._v(_vm._s(label)),(field.tooltip)?_c('b-icon-question-circle-fill',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.topright",value:(field.tooltip),expression:"field.tooltip",modifiers:{"hover":true,"topright":true}}],staticClass:"ml-1"}):_vm._e()]}},{key:"cell(name)",fn:function(ref){
      var value = ref.value;
      var item = ref.item;
return [(!item.id)?_c('span',[_vm._v(_vm._s(_vm.$t('product.types.' + item.type)))]):(item.nameLang)?_c('div',{staticClass:"w-auto d-flex flex-row flex-nowrap align-items-center"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(value),expression:"value",modifiers:{"hover":true,"right":true}}],staticClass:"action",on:{"click":function($event){return _vm.viewProdDetail(item)}}},[_vm._v(_vm._s(value))]),_c('icon-translate',{attrs:{"onClick":function () { return _vm.pushProductEdit(item); },"classes":"pl-1"}})],1):_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(value),expression:"value",modifiers:{"hover":true,"right":true}}],staticClass:"action",on:{"click":function($event){return _vm.viewProdDetail(item)}}},[_vm._v(_vm._s(value))])]}},{key:"cell(must_go)",fn:function(ref){
      var item = ref.item;
return [_c('b-form-checkbox',{attrs:{"value":1,"unchecked-value":0,"switch":""},model:{value:(item['must_go']),callback:function ($$v) {_vm.$set(item, 'must_go', $$v)},expression:"item['must_go']"}})]}},{key:"cell(store_id)",fn:function(ref){
      var value = ref.value;
return [_c('store-badge',{attrs:{"store_id":value}})]}},_vm._l((['cost', 'alternative_cash_amount', 'alternative_credit_amount']),function(name){return {key:("cell(" + name + ")"),fn:function(ref){
      var index = ref.index;
      var value = ref.value;
      var item = ref.item;
return [(name == 'cost' ? !item.id : name == 'alternative_cash_amount' ? item.id || item.type == 'cash_multiple' : item.type != 'credit')?_c('b-input-group',{key:name + '-' + index,staticStyle:{"width":"148px"},attrs:{"id":name + '-' + index}},[_c('b-form-input',{staticClass:"px-2 flex-grow-0 flex-shrink-0",staticStyle:{"width":"32px"},attrs:{"value":item.store_id == 2 ? '$' : '£',"readonly":""}}),_c('b-form-input',{staticClass:"text-right",attrs:{"required":name == 'cost',"number":"","type":"number","step":"0.01","min":name == 'cost' ? 0.01 : null},model:{value:(item[name]),callback:function ($$v) {_vm.$set(item, name, $$v)},expression:"item[name]"}})],1):[_vm._v(_vm._s(_vm.formatWithCurrency(value, item.currency, '-')))]]}}}),{key:"cell(action)",fn:function(ref){
      var item = ref.item;
return [_vm._t("action",null,{"item":item})]}},{key:"cell(payment_frequency)",fn:function(ref){
      var value = ref.value;
      var item = ref.item;
return [(item.isCash && item.type == 'cash_multiple')?_c('b-form-select',{staticClass:"text-capitalize",attrs:{"options":_vm.frequencyOpts},model:{value:(item['cash_period']),callback:function ($$v) {_vm.$set(item, 'cash_period', $$v)},expression:"item['cash_period']"}}):[_vm._v(_vm._s(value))]]}},{key:"cell(payment_numbers)",fn:function(ref){
      var value = ref.value;
      var item = ref.item;
return [(item.isCash && item.type == 'cash_multiple')?_c('b-form-input',{staticClass:"text-center",attrs:{"number":"","type":"number","step":"1","min":"1"},model:{value:(item['cash_times']),callback:function ($$v) {_vm.$set(item, 'cash_times', $$v)},expression:"item['cash_times']"}}):[_vm._v(_vm._s(value))]]}},(_vm.prodStoreValid)?{key:"bottom-row",fn:function(){return [_c('td',{staticClass:"font-weight-bold text-left"},[_vm._v(_vm._s(_vm.$t('order.subtotal')))]),_c('td',{attrs:{"colspan":"2"}}),_c('td',{staticClass:"font-weight-bold text-right"},[_vm._v(_vm._s(_vm.subtotal.allFormatted))])]},proxy:true}:null],null,true)}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.prodStoreValid}},[_vm._v("⚠ "+_vm._s(_vm.$t('competition.same_store_notice')))])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"font-weight-normal m-0"},[_vm._v("Live Draw Prize Settings"),_c('span',{staticClass:"text-danger ml-1"},[_vm._v("*")])])}]

export { render, staticRenderFns }