var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"lazy":"","centered":"","id":"user-details","title":_vm.$t('user.user_details', {
      id: _vm.detailTarget ? ' #' + _vm.detailTarget.user_id : '',
    }),"title-class":"w-100 d-flex justify-content-center align-self-center","header-class":"py-2","footer-class":"py-2","body-class":"py-0"},on:{"hidden":_vm.hideModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
    var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":cancel}},[_vm._v(_vm._s(_vm.$t('action.close')))])]}}])},[_c('form',{attrs:{"id":"user-details-form"}},[(_vm.detailTarget)?_c('UserDetails',{attrs:{"user":_vm.detailTarget,"isAffiliate":true},scopedSlots:_vm._u([{key:"phone",fn:function(ref){
    var val = ref.val;
return [_c('div',{class:"mt-3"},[_c('span',{staticClass:"font-weight-normal text-sm"},[_vm._v(_vm._s(_vm.$t('user.phone')))]),_c('span',{staticClass:"ml-1 text-danger"},[_vm._v("*")]),_c('span',{staticClass:"ml-3 font-weight-light"},[_vm._v(_vm._s(val ? val : _vm.$t('no_data')))])])]}},{key:"address",fn:function(ref){
    var address = ref.address;
return [_c('br'),_c('AddressDetails',{key:("address-details-" + (address ? address.id : 'no data')),attrs:{"address":address,"userStore":_vm.detailTarget.store_id}})]}}],null,false,2799138105)}):_vm._e(),_c('br')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }