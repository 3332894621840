<template>
  <div style="display: contents">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />

    <!-- Search Input -->
    <div class="px-4 pt-3 pb-2 d-flex flex-row flex-wrap align-items-start">
      <b-form-select v-model="queries.store_id" :disabled="!allowMultiStores" class="mb-2 mr-2" style="width: 90px">
        <b-form-select-option selected disabled hidden :value="null">{{ $tc('general.store', 1) }}</b-form-select-option>
        <b-form-select-option :value="''">{{ $t('general.all') + ' ' + $tc('general.store', 2) }}</b-form-select-option>
        <b-form-select-option :value="1">{{ $t('uk') }}</b-form-select-option>
        <b-form-select-option :value="2">{{ $t('address.mexico') }}</b-form-select-option>
      </b-form-select>

      <b-form-input
        v-model="queries.id"
        type="search"
        :placeholder="$t('affiliate.referrer_id')"
        @keyup.enter="onSearch"
        class="mb-2 mr-2"
        style="width: 120px"
      />

      <b-form-input
        v-model="queries.user_name"
        type="search"
        :placeholder="$t('affiliate.referrer_username')"
        @keyup.enter="onSearch"
        class="mb-2 mr-2"
        style="width: 180px"
      />

      <!--<b-form-select v-model="queries['status']" class="mb-2 mr-2" style="width: 90px">
        <b-form-select-option selected disabled hidden :value="null">{{ $t('general.status') }}</b-form-select-option>
        <b-form-select-option :value="''">{{ $t('general.all') }}</b-form-select-option>
        <b-form-select-option :value="1">{{ $t('status.active') }}</b-form-select-option>
        <b-form-select-option :value="0">{{ $t('status.inactive') }}</b-form-select-option>
      </b-form-select>-->

      <div class="d-flex flex-row flex-wrap align-items-center justify-content-xl-between flex-grow-1">
        <button-group :onSearch="onSearch" :onReset="onReset" class="mb-2 mr-2" />
        <slot name="create"></slot>
      </div>
    </div>

    <!-- Table -->
    <b-table
      :fields="fields"
      :items="users ? users.data : []"
      show-empty
      :empty-text="$t('notify.table_no_records')"
      small
      hover
      responsive
      head-variant="light"
      class="m-0 p-0"
    >
      <template #head()="{ label, field }"
        >{{ label }}<b-icon-question-circle-fill v-if="field.tooltip" class="ml-1" v-b-tooltip.hover.topright="field.tooltip"
      /></template>
      <template #cell(user_name)="{ value, item }">
        <template v-if="hasSlot('user_name')"><slot name="user_name" :user="item"></slot></template>
        <template v-else>{{ value }}</template>
      </template>
      <template #cell(total_referrals)="{ value, item }">
        <template v-if="hasSlot('total_referrals')"><slot name="total_referrals" :user="item"></slot></template>
        <template v-else>{{ value }}</template>
      </template>
      <template #cell(campaign_amount)="{ value, item }">
        <template v-if="hasSlot('campaign_amount')"><slot name="campaign_amount" :user="item"></slot></template>
        <template v-else>{{ value }}</template>
      </template>
      <template #cell(commission_balance)="{ value, item }">
        <template v-if="hasSlot('commission_balance')"><slot name="commission_balance" :user="item"></slot></template>
        <template v-else>{{ value }}</template>
      </template>
      <template #cell(actions)="{ item }">
        <slot name="actions" :user_id="item.id" :username="item.name" :user="item"></slot>
      </template>
      <template #cell(status)="{ value, item }">
        <template v-if="hasSlot('status')"><slot name="status" :user="item"></slot></template>
        <bool-badge v-else :value="value" :text="value == 1 ? $t('status.active') : $t('status.inactive')" />
      </template>
    </b-table>

    <paginate v-if="users" :queries="queries" :fetcher="getList" :total="users.total" class="card-footer" />
  </div>
</template>
<script>
import utilsMixin from '@/mixins/utils-mixin'
import { mapGetters } from 'vuex'
import axios from '@/plugins/axios'
import { API_LIST } from '@/utils/consts'
import { notifyError } from '@/utils/index'

export default {
  name: 'AffiliateList',
  mixins: [utilsMixin],
  props: { fields: Array, noInitOnMount: { type: Boolean, default: false } },
  data() {
    return {
      isLoading: true,
      initialParams: {},
      queries: {
        store_id: null,
        id: null,
        user_name: null,
        currency: 'GBP',
        status: null,
        is_affiliate: null,
        page: 1,
        perPage: 10,
      },
    }
  },
  computed: {
    ...mapGetters({
      users: 'request/affiliateList',
      allowMultiStores: 'allowMultiStores',
      selfStore: 'store',
    }),
  },
  methods: {
    getList() {
      this.isLoading = true
      const params = { ...this.queries }
      axios
        .get(API_LIST.get.affiliateList, { params: params })
        .then(res => {
          this.$store.dispatch('request/mutateState', {
            property: 'affiliateList',
            with: res?.data?.data,
          })
        })
        .catch(err => notifyError(err, 'Failed to get affiliate list'))
        .finally(() => (this.isLoading = false))
    },
    onSearch() {
      this.queries['page'] = 1
      this.getList()
    },
    onReset() {
      for (const key in this.queries) {
        this.queries[key] = this.initialParams[key]
      }
      this.onSearch()
    },
  },
  created() {
    this.queries['store_id'] = this.allowMultiStores ? null : this.selfStore

    this.initialParams = { ...this.queries }

    if (!this.noInitOnMount) {
      this.getList()
    } else {
      this.isLoading = false
    }
  },
}
</script>
