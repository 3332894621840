<template>
  <b-badge name="status" pill :variant="['warning', 'success', 'danger'][id]">
    {{ $t(`affiliate.status_list[${id}]`) }}
  </b-badge>
</template>
<script>
export default {
  name: 'AffiliateTransStatus',
  computed: {
    id() {
      return this.value == -1 ? 2 : this.value
    },
  },
  props: {
    value: Number,
  },
}
</script>
