var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{staticClass:"table-custom",attrs:{"body-classes":"p-0 d-flex flex-column"}},[_c('AffiliateReferralList',{attrs:{"fields":[
      { key: 'id', label: _vm.$tc('affiliate.referral_id'), sortable: true },
      {
        key: 'affiliate_name',
        label: _vm.$t('affiliate.referrer_username'),
        stickyColumn: true,
      },
      { key: 'campaign_name', label: _vm.$tc('affiliate.campaign', 1) },
      { key: 'user_name', label: _vm.$t('affiliate.referred_username') },
      { key: 'refer_code', label: _vm.$t('affiliate.refer_code') },
      {
        key: 'total_orders',
        label: _vm.$t('affiliate.total_orders'),
        sortable: true,
      },
      {
        key: 'total_revenue',
        label: _vm.$t('affiliate.total_purchased'),
        sortable: true,
        formatter: function (v) { return _vm.formatWithGBP(v); },
      },
      {
        key: 'total_commission_amount',
        tooltip: _vm.$t('affiliate.available_commission_tooltip'),
        label: _vm.$t('affiliate.total_earned'),
        sortable: true,
        formatter: function (v) { return _vm.formatWithGBP(v); },
      },
      {
        key: 'created_at',
        label: _vm.$t('user.registered_at'),
        sortable: true,
        formatter: function (v) { return _vm.formatLocalDateTime(v); },
      } ]},scopedSlots:_vm._u([{key:"user_name",fn:function(ref){
    var user = ref.user;
return [_c('span',{staticClass:"action",on:{"click":function($event){return _vm.viewDetails(user)}}},[_vm._v(_vm._s(user.user_name))])]}}])}),_c('UserDetailModal',{attrs:{"detailTarget":_vm.detailTarget,"hideModal":_vm.hideModal,"user":_vm.detailTarget,"isAffiliate":true}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }