var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{staticClass:"table-custom",attrs:{"body-classes":"p-0 d-flex flex-column"}},[_c('AffiliateList',{attrs:{"fields":[
      { key: 'id', label: _vm.$tc('affiliate.referrer_id'), sortable: true },
      { key: 'user_name', label: _vm.$t('affiliate.referrer_username'), stickyColumn: true },
      {
        key: 'total_referrals',
        label: _vm.$t('affiliate.total_referrals'),
        sortable: true,
      },
      {
        key: 'pending_commission_amount',
        label: _vm.$t('affiliate.pending_commission_amount'),
        sortable: true,
        formatter: function (v) { return _vm.formatWithGBP(v); },
      },
      {
        key: 'available_commission_amount',
        tooltip: _vm.$t('affiliate.available_commission_tooltip'),
        label: _vm.$t('affiliate.available_commission_amount'),
        sortable: true,
        formatter: function (v) { return _vm.formatWithGBP(v); },
      },
      {
        key: 'commission_balance',
        label: _vm.$t('affiliate.commission_balance'),
        sortable: true,
      },
      {
        key: 'total_commission_amount',
        label: _vm.$t('affiliate.total_earned'),
        sortable: true,
        formatter: function (v) { return _vm.formatWithGBP(v); },
      },
      {
        key: 'total_revenue',
        label: _vm.$t('affiliate.total_purchased'),
        sortable: true,
        formatter: function (v) { return _vm.formatWithGBP(v); },
      },
      {
        key: 'actions',
        label: _vm.$tc('general.action', 1),
      },
      {
        key: 'campaign_amount',
        label: _vm.$t('affiliate.campaign_amount'),
        sortable: true,
      },
      {
        key: 'status',
        label: _vm.$t('general.status'),
        tdClass: 'text-uppercase',
      },
      {
        key: 'created_at',
        label: _vm.$t('general.created_at'),
        sortable: true,
        formatter: function (v) { return _vm.formatLocalDateTime(v); },
      } ]},scopedSlots:_vm._u([{key:"user_name",fn:function(ref){
    var user = ref.user;
return [_c('span',{staticClass:"action",on:{"click":function($event){return _vm.viewDetails(user)}}},[_vm._v(_vm._s(user.user_name))])]}},{key:"total_referrals",fn:function(ref){
    var user = ref.user;
return [_c('span',{staticClass:"action",on:{"click":function($event){return _vm.viewReferrals(user)}}},[_vm._v(_vm._s(user.total_referrals))])]}},{key:"campaign_amount",fn:function(ref){
    var user = ref.user;
return [_c('span',{staticClass:"action",on:{"click":function($event){return _vm.viewCampaigns(user)}}},[_vm._v(_vm._s(user.campaign_amount))])]}},{key:"commission_balance",fn:function(ref){
    var user = ref.user;
return [_c('span',[_vm._v(_vm._s(_vm.formatWithGBP(parseFloat(user.pending_commission_amount) + parseFloat(user.available_commission_amount))))])]}},{key:"status",fn:function(ref){
    var user = ref.user;
return [(user.store_id == 1)?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(user.status ? _vm.$t('action.deactivate') : _vm.$t('action.activate')),expression:"user.status ? $t('action.deactivate') : $t('action.activate')",modifiers:{"hover":true,"top":true}}],staticClass:"font-weight-bold text-uppercase",staticStyle:{"cursor":"pointer"},attrs:{"variant":user.status ? 'success' : 'danger'},on:{"click":function($event){return _vm.viewActivation(user)}}},[_vm._v(" "+_vm._s(user.status == 1 ? '✅ ' + _vm.$t('status.active') : '🚫 ' + _vm.$t('status.inactive'))+" ")]):_c('bool-badge',{staticClass:"font-weight-bold text-uppercase",attrs:{"value":user.status,"text":user.status ? _vm.$t('status.active') : _vm.$t('status.inactive')}})]}},{key:"actions",fn:function(ref){
    var user = ref.user;
return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('affiliate.transactions')),expression:"$t('affiliate.transactions')",modifiers:{"hover":true,"top":true}}],on:{"click":function($event){return _vm.viewTransactions(user)}}},[_c('b-icon-credit-card')],1)]}}])}),_c('b-modal',{attrs:{"lazy":"","centered":"","id":"affiliate-referrals-modal","title":((_vm.$tc('general.affiliate', 1)) + " #" + (_vm.referralsTarget ? _vm.referralsTarget.id : '') + " " + (_vm.$t('affiliate.referred_users'))),"title-class":"w-150 d-flex justify-content-center align-self-center","header-class":"py-2","body-class":"pt-0","hide-footer":""},on:{"hidden":_vm.hideModal}},[_c('AffiliateReferralList',{attrs:{"id":"affiliate-referrals-modal","fields":[
        { key: 'id', label: _vm.$tc('affiliate.referral_id'), sortable: true },
        {
          key: 'affiliate_name',
          label: _vm.$t('affiliate.referrer_username'),
          stickyColumn: true,
        },
        { key: 'campaign_name', label: _vm.$tc('affiliate.campaign', 1) },
        { key: 'user_name', label: _vm.$t('affiliate.referred_username') },
        { key: 'refer_code', label: _vm.$t('affiliate.refer_code') },
        {
          key: 'total_orders',
          label: _vm.$t('affiliate.total_orders'),
          sortable: true,
        },
        {
          key: 'total_revenue',
          label: _vm.$t('affiliate.total_purchased'),
          sortable: true,
          formatter: function (v) { return _vm.formatWithGBP(v); },
        },
        {
          key: 'total_commission_amount',
          tooltip: _vm.$t('affiliate.available_commission_tooltip'),
          label: _vm.$t('affiliate.total_earned'),
          sortable: true,
          formatter: function (v) { return _vm.formatWithGBP(v); },
        },
        {
          key: 'created_at',
          label: _vm.$t('user.registered_at'),
          sortable: true,
          formatter: function (v) { return _vm.formatLocalDateTime(v); },
        } ],"affiliateDetails":_vm.referralsTarget}})],1),_c('b-modal',{attrs:{"lazy":"","centered":"","id":"affiliate-transactions-modal","title":((_vm.$tc('general.affiliate', 1)) + " #" + (_vm.transactionTarget ? _vm.transactionTarget.id : '') + " " + (_vm.$t('affiliate.transactions'))),"title-class":"w-150 d-flex justify-content-center align-self-center","header-class":"py-2","body-class":"pt-0","hide-footer":""},on:{"hidden":_vm.hideModal}},[_c('AffiliateTransactionList',{attrs:{"id":"affiliate-transactions-modal","fields":[
        {
          key: 'id',
          stickyColumn: true,
          label: _vm.$t('general.id'),
          formatter: function (val) { return '#' + val.toString(); },
          sortable: true,
        },
        {
          key: 'affiliate_name',
          label: _vm.$tc('affiliate.referrer_username', 1),
        },
        {
          key: 'withdraw_amount',
          label: _vm.$t('affiliate.withdraw_amount'),
          sortable: true,
          formatter: function (v) { return _vm.formatWithGBP(v); },
        },
        {
          key: 'commission_before',
          label: _vm.$t('affiliate.balance_before'),
          sortable: true,
          formatter: function (v) { return _vm.formatWithGBP(v); },
        },
        {
          key: 'commission_after',
          label: _vm.$t('affiliate.balance_after'),
          sortable: true,
          formatter: function (v) { return _vm.formatWithGBP(v); },
        },
        {
          key: 'comment',
          label: _vm.$t('affiliate.comment'),
        },
        {
          key: 'status',
          label: _vm.$t('general.status'),
          sortable: true,
        },
        {
          key: 'created_at',
          label: _vm.$t('general.created_at'),
          sortable: true,
          formatter: function (v) { return _vm.formatLocalDateTime(v); },
        } ],"affiliateDetails":_vm.transactionTarget}})],1),_c('b-modal',{attrs:{"lazy":"","centered":"","id":"affiliate-campaigns-modal","title":((_vm.$tc('general.affiliate', 1)) + " #" + (_vm.campaignTarget ? _vm.campaignTarget.id : '') + " " + (_vm.$tc('affiliate.campaign', 2))),"title-class":"w-150 d-flex justify-content-center align-self-center","header-class":"py-2","body-class":"pt-0","hide-footer":""},on:{"hidden":_vm.hideModal}},[_c('AffiliateCampaignList',{attrs:{"id":"affiliate-campaigns-modal","fields":[
        {
          key: 'id',
          stickyColumn: true,
          label: _vm.$t('general.id'),
          sortable: true,
        },
        {
          key: 'affiliate_name',
          label: _vm.$t('affiliate.referrer_username'),
        },
        {
          key: 'title',
          label: _vm.$t('affiliate.campaign_name'),
        },
        {
          key: 'refer_code',
          label: _vm.$t('affiliate.refer_code'),
          sortable: true,
        },
        {
          key: 'referrals',
          label: _vm.$t('affiliate.total_referrals'),
          sortable: true,
        },
        {
          key: 'total_orders',
          label: _vm.$t('affiliate.total_orders'),
          sortable: true,
        },
        {
          key: 'campaign_hits',
          label: _vm.$t('affiliate.campaign_hits'),
          sortable: true,
        },
        {
          key: 'total_commission_amount',
          tooltip: _vm.$t('affiliate.available_commission_tooltip'),
          label: _vm.$t('affiliate.total_earned'),
          sortable: true,
          formatter: function (v) { return _vm.formatWithGBP(v); },
        },
        {
          key: 'total_revenue',
          label: _vm.$t('affiliate.total_purchased'),
          sortable: true,
          formatter: function (v) { return _vm.formatWithGBP(v); },
        },
        {
          key: 'created_at',
          label: _vm.$t('general.created_at'),
          sortable: true,
          formatter: function (v) { return _vm.formatLocalDateTime(v); },
        } ],"affiliateDetails":_vm.campaignTarget}})],1),_c('UserDetailModal',{attrs:{"detailTarget":_vm.detailTarget,"hideModal":_vm.hideModal,"user":_vm.detailTarget,"isAffiliate":true}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }