<template>
  <div style="display: contents">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />

    <!-- Search Input -->
    <div v-if="!this.affiliateDetails" class="px-4 pt-3 pb-2 d-flex flex-row flex-wrap align-items-start">
      <b-form-select v-model="queries.store_id" :disabled="!allowMultiStores" class="mb-2 mr-2" style="width: 90px">
        <b-form-select-option selected disabled hidden :value="null">{{ $tc('general.store', 1) }}</b-form-select-option>
        <b-form-select-option :value="''">{{ $t('general.all') + ' ' + $tc('general.store', 2) }}</b-form-select-option>
        <b-form-select-option :value="1">{{ $t('uk') }}</b-form-select-option>
        <b-form-select-option :value="2">{{ $t('address.mexico') }}</b-form-select-option>
      </b-form-select>

      <!--<b-form-input v-model="queries.id" type="search" placeholder="User ID" @keyup.enter="onSearch" class="mb-2 mr-2" style="width: 100px" />-->

      <!--<b-form-input v-model="queries.affiliate_id" type="search" placeholder="User ID" @keyup.enter="onSearch" class="mb-2 mr-2" style="width: 100px" />-->

      <b-form-input
        v-model="queries.affiliate_name"
        type="search"
        :placeholder="$t('affiliate.referrer_username')"
        @keyup.enter="onSearch"
        class="mb-2 mr-2"
        style="width: 180px"
      />

      <b-form-input
        v-model="queries.title"
        type="search"
        :placeholder="$t('affiliate.campaign_name')"
        @keyup.enter="onSearch"
        class="mb-2 mr-2"
        style="width: 180px"
      />
      
      <b-form-input
        v-model="queries.refer_code"
        type="search"
        :placeholder="$t('affiliate.refer_code')"
        @keyup.enter="onSearch"
        class="mb-2 mr-2"
        style="width: 180px"
      />

      <!--<date-range-picker
        v-model="dateRange"
        :locale-data="{ format: 'yyyy-mm-dd' }"
        :auto-apply="true"
        :show-dropdowns="true"
        :ranges="ranges"
        :max-date="maxDate"
        class="mb-2 mr-2"
        style="width: max-content; min-width: 220px"
      />-->

      <div class="d-flex flex-row flex-wrap align-items-center justify-content-xl-between flex-grow-1">
        <button-group :onSearch="onSearch" :onReset="onReset" class="mb-2 mr-2" />
        <slot name="create"></slot>
      </div>
    </div>

    <!-- Table -->
    <b-table
      :fields="fields"
      :items="users ? users.data : []"
      show-empty
      :empty-text="$t('notify.table_no_records')"
      small
      hover
      responsive
      head-variant="light"
      class="m-0 p-0"
    >
      <template #head()="{ label, field }">{{ label }}<b-icon-question-circle-fill v-if="field.tooltip" class="ml-1" v-b-tooltip.hover.topright="field.tooltip" /></template>
      <template #cell(affiliate_name)="{ value, item }">
        <template v-if="hasSlot('affiliate_name')"><slot name="affiliate_name" :user="item"></slot></template>
        <template v-else>{{ value }}</template>
      </template>
      <template #cell(referrals)="{ value, item }">
        <template v-if="hasSlot('referrals')"><slot name="referrals" :user="item"></slot></template>
        <template v-else>{{ value }}</template>
      </template>
    </b-table>

    <paginate v-if="users" :queries="queries" :fetcher="getList" :total="users.total" class="card-footer" />
  </div>
</template>
<script>
import utilsMixin from '@/mixins/utils-mixin'
import { formatWithGBP } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'
import axios from '@/plugins/axios'
import { API_LIST } from '@/utils/consts'
import { notifyError, notifySuccess } from '@/utils/index'
import { formatDayEndUtc, formatDayStartUtc, formatLocalDateTime, formatUtcDateTime, setupDateRanges } from '@/utils/dateTimeUtils'

export default {
  name: 'AffiliateCampaignList',
  mixins: [utilsMixin],
  props: {
    affiliateDetails: Object,
    fields: Array,
    noInitOnMount: { type: Boolean, default: false },
  },
  data() {
    return {
      ranges: setupDateRanges(),
      maxDate: formatLocalDateTime(null, 'YYYY-MM-DD', true, true),
      isLoading: true,
      confirmTarget: null,
      initialParams: {},
      queries: {
        store_id: null,
        id: null,
        affiliate_id: null,
        affiliate_name: null,
        title: null,
        created_start: null,
        created_end: null,
        currency: 'GBP',
        page: 1,
        perPage: 10,
      },
    }
  },
  computed: {
    ...mapGetters({
      users: 'request/AffiliateCampaignList',
      allowMultiStores: 'allowMultiStores',
      selfStore: 'store',
    }),
    dateRange: {
      get() {
        return {
          startDate: formatLocalDateTime(this.queries.created_start, 1, false, true),
          endDate: formatLocalDateTime(this.queries.created_end, 1, false, true),
        }
      },
      set(newVal) {
        this.queries['created_start'] = formatDayStartUtc(newVal.startDate, 0, false, true)
        this.queries['created_end'] = formatDayEndUtc(newVal.endDate, 0, false, true)
        return newVal
      },
    },
  },
  methods: {
    getList() {
      this.isLoading = true
      const params = { ...this.queries }
      if (this.affiliateDetails) params['affiliate_id'] = this.affiliateDetails.id
      axios
        .get(API_LIST.get.affiliateCampaigns, { params: params })
        .then(res => {
          this.$store.dispatch('request/mutateState', {
            property: 'AffiliateCampaignList',
            with: res?.data?.data,
          })
        })
        .catch(err => notifyError(err, 'Failed to get affiliate campaign list'))
        .finally(() => (this.isLoading = false))
    },
    onApprove(obj) {
      this.confirmTarget = { ...obj, isReject: false }
      this.$bvModal.show('confirm')
    },
    onReject(obj) {
      this.confirmTarget = { ...obj, isReject: true }
      this.$bvModal.show('confirm')
    },
    onSearch() {
      this.queries['page'] = 1
      this.getList()
    },
    onReset() {
      for (const key in this.queries) {
        this.queries[key] = this.initialParams[key]
      }
      this.dateRange['startDate'] = null
      this.dateRange['endDate'] = null
      this.onSearch()
    },
    hideConfirm() {
      this.confirmTarget = null
    },
    formatWithGBP,
  },
  created() {
    this.queries['store_id'] = this.allowMultiStores ? null : this.selfStore

    this.initialParams = { ...this.queries }

    if (!this.noInitOnMount) {
      this.getList()
    } else {
      this.isLoading = false
    }
  },
}
</script>
