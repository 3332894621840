<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <AffiliateReferralList
      :fields="[
        { key: 'id', label: $tc('affiliate.referral_id'), sortable: true },
        {
          key: 'affiliate_name',
          label: $t('affiliate.referrer_username'),
          stickyColumn: true,
        },
        { key: 'campaign_name', label: $tc('affiliate.campaign', 1) },
        { key: 'user_name', label: $t('affiliate.referred_username') },
        { key: 'refer_code', label: $t('affiliate.refer_code') },
        {
          key: 'total_orders',
          label: $t('affiliate.total_orders'),
          sortable: true,
        },
        {
          key: 'total_revenue',
          label: $t('affiliate.total_purchased'),
          sortable: true,
          formatter: v => formatWithGBP(v),
        },
        {
          key: 'total_commission_amount',
          tooltip: $t('affiliate.available_commission_tooltip'),
          label: $t('affiliate.total_earned'),
          sortable: true,
          formatter: v => formatWithGBP(v),
        },
        {
          key: 'created_at',
          label: $t('user.registered_at'),
          sortable: true,
          formatter: v => formatLocalDateTime(v),
        },
      ]"
    >
      <template #user_name="{ user }">
        <span class="action" @click="viewDetails(user)">{{ user.user_name }}</span>
      </template>
    </AffiliateReferralList>

    <UserDetailModal :detailTarget="detailTarget" :hideModal="hideModal" :user="detailTarget" :isAffiliate="true" />
  </card>
</template>
<script>
import routerMixin from '@/mixins/router-mixin'
import UserDetailModal from '@/components/Users/UserDetailModal.vue'
import AffiliateReferralList from '@/components/Affiliates/AffiliateReferralList.vue'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { floatAddition, formatWithGBP } from '@/utils/numberUtils'

export default {
  mixins: [
    routerMixin, // hasPermission
  ],
  components: {
    AffiliateReferralList,
    UserDetailModal,
  },
  data() {
    return {
      currency: 'GBP',
      detailTarget: null,
    }
  },
  methods: {
    viewDetails(obj) {
      this.detailTarget = obj
      this.$bvModal.show('user-details')
    },
    hideModal() {
      this.detailTarget = null
    },
    formatLocalDateTime,
    formatWithGBP,
    floatAddition,
  },
}
</script>
<style scoped>
::v-deep .modal-dialog {
  width: max-content;
  min-width: 45%;
  max-width: 75%;
}
@media screen and (max-width: 1024px) {
  ::v-deep .modal-dialog {
    min-width: 75%;
    max-width: 90%;
  }
}
</style>
