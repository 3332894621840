<template>
  <div class="row mt-4 rounded bg-white row-cols-1 row-cols-xl-2 flex-column">
    <div v-b-toggle.instant-win-edit-form :class="'col-12 col-xl-12 w-100 d-flex align-items-center' + (visible ? ' pt-4 px-4' : ' p-4')">
      <label class="font-weight-normal m-0">Instant Win Prize Settings</label>
      <b-icon-question-circle-fill class="ml-1" v-b-tooltip.hover.rightbottom="$t('instant_win.generate[3]')" />
      <span v-if="subtotal.allFormatted" class="text-success ml-auto mr-2">{{ $t('order.subtotal') }}: {{ subtotal.allFormatted }}</span>
      <b-icon-caret-up-fill v-if="visible" :class="subtotal.allFormatted ? '' : 'ml-auto'" />
      <b-icon-caret-down-fill v-else :class="subtotal.allFormatted ? '' : 'ml-auto'" />
    </div>

    <b-collapse id="instant-win-edit-form" :visible="visible" @show="visible = true" @hidden="visible = false" class="col-12 col-xl-12 mb-4">
      <b-row cols="1" cols-xl="2">
        <b-col class="px-4">
          <b-form-group label="Prize Type" label-for="rwd-type" label-class="form-label-h" class="d-inline-block w-50 pr-2 mt-2 mb-0">
            <b-form-select
              id="rwd-type"
              v-model="rwdTypeInp"
              :options="[
                { value: null, text: 'Select Prize Type', disabled: true },
                { value: 'credit', text: $t('product.types.credit') },
                { value: 'cash', text: $t('product.types.cash') },
                { value: 'cash_multiple', text: $t('product.types.cash_multiple') },
                { value: 'product', text: 'Product' },
              ]"
              @change="onChangeType"
              :disabled="noEditAfterPublish || maxTix < 1" />
          </b-form-group>
          <b-form-group :label="$t('competition.prize_cost')" label-for="rwd-amt" label-class="form-label-h" class="d-inline-block w-50 pl-2 mt-2 mb-0">
            <b-input-group>
              <b-form-input value="£" readonly class="px-2 flex-grow-0 flex-shrink-0" style="width: 32px" />
              <b-form-input
                id="rwd-amt"
                v-model="rwdAmtInp"
                @input="onChangeAmt"
                number
                type="number"
                step="0.01"
                :min="rwdTypeInp ? 0.01 : null"
                :disabled="noEditAfterPublish || maxTix < 1 || !!instantWinProdDetail"
                class="text-right" />
            </b-input-group>
          </b-form-group>

          <b-form-group v-if="rwdTypeInp == 'cash_multiple' || instantWinProdDetail" :label="$t('competition.alt_cash')" label-for="rwd-alt-cash" class="d-inline-block w-50 pr-2 mt-4 mb-0">
            <b-input-group>
              <b-form-input value="£" readonly class="px-2 flex-grow-0 flex-shrink-0" style="width: 32px" />
              <b-form-input id="rwd-alt-cash" v-model="rwdAltCashInp" number type="number" step="0.01" min="0" :disabled="noEditAfterPublish || maxTix < 1" class="text-right" />
            </b-input-group>
          </b-form-group>
          <b-form-group
            v-if="rwdTypeInp != 'credit' || instantWinProdDetail"
            :label="$t('competition.alt_credit')"
            label-for="rwd-alt-credit"
            :class="'d-inline-block w-50 mt-4 mb-0 ' + (rwdTypeInp == 'cash_multiple' || instantWinProdDetail ? 'pl-2' : 'pr-2')">
            <b-input-group>
              <b-form-input value="£" readonly class="px-2 flex-grow-0 flex-shrink-0" style="width: 32px" />
              <b-form-input id="rwd-alt-credit" v-model="rwdAltCreditInp" number type="number" step="0.01" min="0" :disabled="noEditAfterPublish || maxTix < 1" class="text-right" />
            </b-input-group>
          </b-form-group>

          <template v-if="rwdTypeInp == 'cash_multiple'">
            <b-form-group :label="$t('product.pymt_freq')" label-for="rwd-pymt-freq" class="d-inline-block w-50 pr-2 mt-4 mb-0">
              <b-form-select id="rwd-pymt-freq" v-model="rwdPymtFreqInp" :options="['weekly', 'monthly']" :disabled="noEditAfterPublish || maxTix < 1" class="text-capitalize" />
            </b-form-group>
            <b-form-group :label="$t('product.pymt_times')" label-for="rwd-pymt-times" class="d-inline-block w-50 pl-2 mt-4 mb-0">
              <b-form-input id="rwd-pymt-times" v-model="rwdPymtTimesInp" number type="number" step="1" min="1" :disabled="noEditAfterPublish || maxTix < 1" :placeholder="$t('product.pymt_times')" />
            </b-form-group>
          </template>

          <b-form-group :label="$t('product.must_go')" label-for="rwd-must-go" :class="'d-inline-block w-50 mt-4 mb-0 ' + (rwdTypeInp == 'cash' ? 'pl-2' : 'pr-2')">
            <b-form-select
              id="rwd-must-go"
              v-model="rwdMustGoInp"
              :options="[
                { value: 1, text: $t('yes') },
                { value: 0, text: $t('no') },
              ]"
              :disabled="noEditAfterPublish || maxTix < 1" />
          </b-form-group>

          <!-- Auto generate -->
          <b-form-group label="Generation Method" class="w-full mt-4 mb-0">
            <b-input-group class="form-control p-0 mx-0">
              <b-input-group-prepend>
                <b-button disabled class="text-left border-0" style="width: 148px; height: 34px">{{ $t('instant_win.generate[0]') }}</b-button>
              </b-input-group-prepend>
              <b-form-input
                v-model="shuffleInp"
                :disabled="noEditAfterPublish || maxTix < 1 || rwdAmtInp <= 0"
                number
                type="number"
                min="1"
                step="1"
                :max="maxTix"
                :placeholder="$t('ticket.quantity_notice')[0]"
                :state="shuffleInpState"
                aria-describedby="shuffleInpInvalid"
                class="m-0 py-0 px-2 border-top-0 border-bottom-0 border-left border-right"
                style="height: 34px" />
              <b-input-group-append>
                <b-button variant="outline-primary" :disabled="!shuffleInpState" @click="generateShuffleTix" class="border-0" style="height: 34px">
                  {{ $t('instant_win.generate[2]') }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-form-invalid-feedback :state="shuffleInpState" id="shuffleInpInvalid">⚠ {{ $t('ticket.max_ticket_notice') }}</b-form-invalid-feedback>
          </b-form-group>

          <!-- Manual generate -->
          <b-form-group class="w-100 mt-2 mb-0">
            <b-form-tags v-model="manualTix" :disabled="noEditAfterPublish" no-outer-focus :class="manualTix && manualTix.length > 0 ? 'py-2 mt-2' : 'border-0 px-0'">
              <template v-slot="{ tags, addTag, removeTag }">
                <ul v-if="tags && tags.length > 0" class="list-inline m-0">
                  <li v-for="tag in tags" :key="tag" class="list-inline-item mb-2">
                    <b-form-tag @remove="removeTag(tag)" :disabled="noEditAfterPublish" :title="tag" variant="info" class="text-capitalize">
                      {{ formatTag(tag) }}
                    </b-form-tag>
                  </li>
                </ul>
                <b-input-group class="form-control p-0 m-0">
                  <b-input-group-prepend>
                    <b-button disabled class="text-left border-0" style="width: 148px; height: 34px">
                      {{ $t('instant_win.generate[1]') }}
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="manualInp"
                    :disabled="noEditAfterPublish || maxTix < 1 || rwdAmtInp <= 0"
                    number
                    type="number"
                    min="1"
                    :placeholder="$t('ticket.quantity_notice')[1]"
                    @keyup.prevent="_addTag($event, addTag)"
                    @keydown.enter.prevent="_addTag($event, addTag)"
                    :state="manualInpState"
                    aria-describedby="manualInpInvalid"
                    class="m-0 py-0 px-2 border-top-0 border-bottom-0 border-left border-right"
                    style="height: 34px" />
                  <b-input-group-append>
                    <b-button variant="outline-primary" :disabled="!tags || tags.length < 1" @click="generateManualTix" class="border-0" style="height: 34px">
                      {{ $t('instant_win.generate[2]') }}
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </template>
            </b-form-tags>
            <b-form-invalid-feedback :state="manualInpState" id="manualInpInvalid">⚠ {{ $t('ticket.invalid_tix_num') }}</b-form-invalid-feedback>
          </b-form-group>

          <p class="text-xs mt-1 mb-0">⚠ {{ $t('ticket.max_avail_tix') + ': ' + maxTix }}</p>
          <!-- <p v-if="maxProd" class="text-xs mt-1 mb-0 text-danger">⚠ Maximum number of products: {{ maxTix }}</p> -->
        </b-col>
        <b-col class="px-4">
          <div class="mt-1 d-flex align-items-center action" v-b-toggle.instant-win-prod-rule>
            <b-icon-question-circle-fill class="mr-2" />
            How to Add Instant Win Prizes for Product Type？
          </div>
          <b-collapse id="instant-win-prod-rule">
            <p class="mt-1 mb-0 text-sm pl-4">1. Enter the appropriate values for Cash Alt, Credit Alt, and Must Go based on your needs.</p>
            <p class="mt-1 mb-0 text-sm pl-4">2. In the Generation Method, enter the number of tickets you want to generate.</p>
            <p class="mt-1 mb-0 text-sm pl-4">3. Click the Generate button to add the specified number of tickets. Each ticket will have a Product as its associated prize.</p>
          </b-collapse>

          <ProductDetails v-if="instantWinProdDetail" :product="instantWinProdDetail" simplified />
        </b-col>
      </b-row>

      <!-- tickets table -->
      <div class="px-2">
        <div class="w-100 d-flex align-items-center mt-4">
          <label class="m-0">{{ $t('instant_win.tickets') }}</label>
          <b-button v-if="form.instantWin.length > 0" v-b-modal.confirm-clear-all :disabled="noEditAfterPublish" variant="outline-danger" class="ml-2">{{ $t('instant_win.clear_all[0]') }}</b-button>
        </div>

        <p v-if="noEditAfterPublish" class="text-xs mt-1 mb-0 text-danger">⚠ Only the Instant Win settings of draft Competitions can be edited.</p>
        <p v-else-if="form.stock_amount < 1" class="text-xs mt-1 mb-0 text-danger">⚠ Please input the '{{ $t('competition.total_tix') }}' to set up Instant Win Prize.</p>

        <b-table
          ref="ticketsTable"
          show-empty
          hover
          responsive
          head-variant="light"
          sticky-header="380px"
          class="mt-2 mb-0 mx-0 p-0 h-90"
          sort-by="ticket_number"
          :sort-desc="true"
          :fields="ticketFields"
          :items="form.instantWin"
          :tbody-tr-class="rowClass">
          <template #cell(name)="{ value, item }">
            <span v-if="!item.id">{{ $t('product.types.' + item.type) }}</span>
            <div v-else-if="item.nameLang" class="w-auto d-flex flex-row flex-nowrap align-items-center">
              <div class="action" @click="viewProdDetail(item)" style="white-space: normal; min-width: 8rem">{{ value }}</div>
              <icon-translate :onClick="() => pushProductEdit(item)" classes="pl-1" />
            </div>
            <div v-else class="action" @click="viewProdDetail(item)" style="white-space: normal; min-width: 12rem">
              {{ value }}
            </div>
          </template>
          <template #cell(action)="{ item }">
            <b-button variant="outline-danger" size="xs" @click="removeTicket(item.ticket_number)" :disabled="noEditAfterPublish"><b-icon-trash /></b-button>
          </template>
          <template #bottom-row v-if="form.instantWin.length > 0">
            <td class="font-weight-bold text-left">{{ $t('order.subtotal') }}</td>
            <td class="font-weight-bold text-left">{{ form.instantWin.length }} Tickets</td>
            <td :colspan="ticketFields.length - 8"></td>
            <td class="font-weight-bold text-right">{{ subtotal.allFormatted }}</td>
          </template>
        </b-table>

        <b-form-group class="mt-4 mb-0" label-class="d-flex align-items-center form-label-h">
          <template #label>
            {{ $t('instant_win.descr') }}
            <b-icon-question-circle-fill class="mx-2" v-b-tooltip.hover.topright="$t('notify.translate_notice[0]')" />
            <b-button v-if="form.instantWin.length > 0" @click="applyDescrTemplate" :disabled="noEditAfterPublish || maxTix < 1" variant="outline-primary">Apply Template</b-button>
          </template>
          <b-form-textarea
            v-model="description"
            :disabled="noEditAfterPublish || form.stock_amount < 1"
            :placeholder="$t('instant_win.descr')"
            size="lg"
            rows="4"
            no-resize
            style="font-size: 0.85rem" />
        </b-form-group>
      </div>
    </b-collapse>

    <template v-if="!noEditAfterPublish">
      <b-modal
        id="stock-amount-change"
        lazy
        centered
        header-class="pb-0"
        body-class="py-0"
        body-style
        @hidden="onStockAmountHidden"
        @ok="onStockAmountOk"
        :cancel-title="$t('instant_win.clear_all[5]')"
        :ok-title="$t('instant_win.clear_all[6]')"
        :title="$t('instant_win.clear_all[3]')">
        {{ $t('instant_win.clear_all[4]') }}
      </b-modal>

      <b-modal id="confirm-clear-all" lazy centered dialog-class="w-30" header-class="pb-0" body-class="py-0" body-style @ok="clearAll">
        {{ $t('instant_win.clear_all[2]') }}
      </b-modal>
    </template>
  </div>
</template>
<script>
import ProductDetails from '@/components/Product/ProductDetails.vue';
import { noTransFormatter } from '@/utils/index';
import { formatWithCurrency, formatWithGBP } from '@/utils/numberUtils';

export default {
  name: 'InstantWinSetting',

  components: {
    ProductDetails,
  },

  props: {
    lookupType: String,
    form: Object,
    noEditAfterPublish: Boolean,
    viewProdDetail: Function,
    instantWinProdDetail: Object,
    prodStoreValid: Boolean,
    subtotal: Object,
  },
  data() {
    return {
      visible: this.form.instantWin.length > 0,
      rwdTypeInp: null,
      rwdAmtInp: 0,
      rwdMustGoInp: 0,
      rwdPymtFreqInp: null,
      rwdPymtTimesInp: null,
      rwdAltCashInp: 0,
      rwdAltCreditInp: 0,
      shuffleInp: '',
      manualInp: '',
      manualTix: [],
      description: '',
      initStockAmt: this.form.stock_amount || 0,
    }
  },
  computed: {
    // main form
    maxTix() {
      return this.form.stock_amount - this.form.instantWin.length
    },
    // maxProd() {
    //   return this.instantWinProdDetail ? this.availAmt(this.instantWinProdDetail) : null
    // },
    shuffleInpState() {
      return !this.shuffleInp ? null : this.shuffleInp > 0 && this.shuffleInp <= this.form.stock_amount - this.form.instantWin.length ? true : false
    },
    manualInpState() {
      return !this.manualInp ? null : this.manualInp > 0 && this.manualInp <= this.form.stock_amount && !this.isExistingTicket(this.manualInp) ? true : false
    },

    // tickets table
    ticketFields() {
      const tmp = [
        // 'status',
        { key: 'ticket_number', label: this.$tc('order.ticket_number', 1), sortable: true },
        { key: 'must_go', tooltip: this.$t('competition.must_go_notice'), sortable: true, formatter: (v) => (v ? this.$t('yes') : this.$t('no')) },
        { key: 'name', label: this.$t('competition.prize_name'), sortable: true, formatter: noTransFormatter, tdClass: 'text-truncate' },
        { key: 'cost', label: this.$t('general.cost'), sortable: true, formatter: (v) => formatWithCurrency(v, this.form.currency, '-'), class: 'text-right' },
        {
          key: 'alternative_cash_amount',
          label: this.$t('competition.alt_cash'),
          sortable: true,
          formatter: (v) => formatWithCurrency(v, this.form.currency, '-'),
          class: 'text-right',
        },
        {
          key: 'alternative_credit_amount',
          label: this.$t('competition.alt_credit'),
          sortable: true,
          formatter: (v) => formatWithCurrency(v, this.form.currency, '-'),
          class: 'text-right',
        },
        { key: 'action', label: this.$tc('general.action', 1), class: 'text-right' },
        { key: 'payment_frequency', sortable: true, formatter: (value, key, item) => (item.isCash ? item.cash_period : null) ?? 'one-off', tdClass: 'text-capitalize' },
        {
          key: 'payment_numbers',
          label: this.$t('product.pymt_times'),
          sortable: true,
          formatter: (value, key, item) => (item.isCash ? item.cash_times : null) ?? 1,
          tdClass: 'text-capitalize',
          class: 'text-center',
        },
      ]
      if (this.form.id) {
        tmp.splice(0, 0, { key: 'reward_id', label: this.$t('general.id'), sortable: true })
      }
      return tmp
    },
  },

  watch: {
    // show modal when form changed, differ to init amt and has instant tickets
    'form.stock_amount'(newVal, oldVal) {
      if (newVal != oldVal && newVal != this.initStockAmt && this.form.instantWin.length > 0) {
        this.$bvModal.show('stock-amount-change')
        if (this.initStockAmt == 0 && oldVal > 0) {
          this.initStockAmt = oldVal
        }
      }
    },
    description(newVal, oldVal) {
      this.form['consolation_description'] = newVal
    },
    lookupType(newVal, oldVal) {
      if (newVal != oldVal && !newVal) {
        this.rwdTypeInp = null
      }
    },
    instantWinProdDetail(newVal, oldVal) {
      this.rwdAmtInp = newVal?.cost
    },
    prodStoreValid(newVal, oldVal) {
      if (!newVal) {
        this.clearAll()
      }
    },
  },

  methods: {
    isExistingTicket(number) {
      return this.form.instantWin.some((item) => String(item.ticket_number) == String(number))
    },
    onChangeAmt($event) {
      this.rwdMustGoInp = 0
    },
    onChangeType($event) {
      if ($event == 'product') {
        this.$emit('update:lookupType', 'instantWin')
        this.$bvModal.show('product-lookup')
      } else {
        this.$emit('update:instantWinProdDetail', null)
      }
      this.rwdMustGoInp = 0
      this.rwdPymtFreqInp = $event == 'cash_multiple' ? 'weekly' : null
      this.rwdPymtTimesInp = $event.includes('cash') ? 1 : null
    },
    generateTix(ticket_number) {
      const isCash = this.rwdTypeInp?.includes('cash') ? 'cash_prize_' + Date.now() : null
      const res = {
        ...this.instantWinProdDetail, // store_id, stock_amount, available_amount, cost
        type: this.instantWinProdDetail ? this.instantWinProdDetail.type : this.rwdTypeInp,
        store_id: this.form.store_id,
        must_go: this.rwdMustGoInp,
        ticket_number,
        isCash,
        amount: 1,
        alternative_credit_amount: this.rwdAltCashInp,
        alternative_cash_amount: this.rwdAltCreditInp,
        cash_times: isCash ? this.rwdPymtTimesInp : null,
        cash_period: this.rwdTypeInp == 'cash_multiple' ? this.rwdPymtFreqInp : null,
      }
      if (isCash || this.rwdTypeInp == 'credit') {
        res['cost'] = this.rwdAmtInp
      }
      return res
    },
    generateShuffleTix() {
      let count = +this.shuffleInp
      while (count > 0) {
        const num = Math.floor(Math.random() * this.form.stock_amount) + 1
        if (!this.isExistingTicket(num)) {
          this.form.instantWin.push(this.generateTix(num))
          count--
        }
      }
      this.shuffleInp = ''
    },
    _addTag($event, addTag) {
      $event.preventDefault()
      // key code 188 -> comma
      // key code 13 -> enter
      if (this.manualInpState && ($event.keyCode === 188 || $event.keyCode === 13)) {
        const isExistingTag = this.manualTix.some((tag) => JSON.parse(tag).ticket_number == this.manualInp)
        if (!isExistingTag) {
          addTag(JSON.stringify(this.generateTix(this.manualInp)))
        }
        this.manualInp = ''
      }
    },
    formatTag(tag) {
      const item = JSON.parse(tag)
      return `No.${item.ticket_number} 🎟️ ${item.id ? item.name + ' * 1' : (item.isCash > 0 ? this.$t('competition.credit') : this.$t('competition.credit')) + ' ' + formatWithGBP(item.cost)}`
    },
    generateManualTix() {
      this.manualTix.forEach((tag) => this.form.instantWin.push(JSON.parse(tag)))
      this.manualTix = []
      this.manualInp = ''
    },
    rowClass(item, type) {
      if (this.form.status > 1 && item && type === 'row' && item.must_go) {
        return 'tr-line-thought'
      }
    },
    removeTicket(ticket_number) {
      const i = this.form.instantWin.findIndex((item) => item.ticket_number == ticket_number)
      this.form.instantWin.splice(i, 1)
    },
    clearAll() {
      this.form.instantWin = []
      this.form.consolation_description = this.description = ''
      this.$emit('update:instantWinProdDetail', null)
      this.rwdTypeInp = null
      this.rwdAmtInp = null
      this.rwdMustGoInp = 0
      this.shuffleInp = null
      this.manualInp = null
      this.manualTix = []
    },

    onStockAmountHidden() {
      this.form['stock_amount'] = this.initStockAmt // reset form to init amt
    },
    onStockAmountOk() {
      this.initStockAmt = this.form.stock_amount // get new init amt from from
      this.clearAll()
    },

    applyDescrTemplate() {
      const str =
        this.form?.instantWin?.reduce?.((obj, item, index, arr) => {
          const key = item.name ? item.name.replaceAll(' ', '_') : `${item.cost}${item.isCash ? '_Cash_Prizes' : '_Credit_Prizes'}`
          obj[key] = (obj[key] || 0) + 1
          if (index == arr.length - 1) {
            return Object.keys(obj).reduce((acc, key) => acc + `<p style='font-weight:600'>&#9733 ${obj[key]} x ${key.replaceAll('_', ' ')}</p>`, '')
          } else {
            return obj
          }
        }, {}) || ''
      this.description = ` <div><p>No need to wait for the draw date</p><p>We have <span style='font-weight:600'>${this.form.instantWin.length} Freed Instant Win prizes</span> up for grabs <br> </p>${str}<p><br>Randomly draw any of the following tickets numbers and win one of the above prizes!</p></div>`
    },
  },
}
</script>
